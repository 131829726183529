import { experiments } from 'cb-wallet-data/experiments';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { DeepReadonly } from 'ts-essentials';

import { FeatureConfig } from './types';

const { MNEMONIC, PRIVATE_KEY, DAPP_PROVIDER } = AccountType;

export const featureNames = [
  'scw_webauthn_p256_authentication',
  'did_settings_claim_for_nonprimary_wallet_groups',
  'save_secret_confirmation_on_signout',
  'top_up_for_web3_transactions',
  'utxo_announcement_banner',
  'export_private_key',
  'account_backup_reminders',
  'eoa_approval_fraud_warning',
  'dapp_tx_confirmation_scam_warning',
  'human_readable_transactions',
  'assets_tab_nux_cards',
  'fund_and_swap_on_explore',
  'browser_tab_screenshot',
  'browser_cache_clear',
  'nux_nft_incentive_entry_modal',
  'browser_nux_tray',
  'base_browser_nux_tray',
  'instant_funding_flow',
  'nft_pfp',
  'wallet_pfp',
  'cbpay_sdk',
  'guest_checkout',
  'show_notifications_permissions_post_onboarding',
  'wallet_settings_hub_notif_optin',
  'load_existing_browser_tab',
  'wallet_extension_promotion_announcement',
  'usdc_rewards_v2',
  'usdc_rewards_summary',
  'skip_restore_wallet',
  'bridge_base_upsell',
  // buy and mint flow - start
  'activation_mint_dot_fun_collections',
  'activation_cb_nft_collections',
  'activation_trending_collections_tab',
  'mint_streak_collection_gtm',
  'cbpay_provider_interstitial',
  // explore modules
  'explore_mint_dot_fun_collections',
  'explore_cb_nft_collections',
  'explore_trending_collections_tab',
  'explore_upcoming_drop_notification_link',
  'explore_partner_drop',
  'explore_partner_drop_v2',
  // buy and mint flow - end
  'web3_starter_kit_on_asset_list',
  'initialize_local_currency',
  'nft_incentive_with_usdc',
  'authenticated_webview_wsst_flow',

  // onramp
  'onramp_currency_selector',
  'onramp_last_used_provider',
  'onramp_swap_assets',
  'onramp_native_buy',
  'onramp_ocb_deeplink',
  'onramp_stripe_integration',
  'onramp_cb_native_buy',
  'onramp_unsupported_payment_method_tray',
  'onramp_mgx_unconnected_state',
  'onramp_confirmation_screen',
  'scw_onramp_native_buy',

  // currency formatters
  'fiat_ui_copies',

  // messaging
  'messaging_payment_request',
  'messaging_mute_conversation',
  'messaging_subscribe',
  'messaging_suggestions',
  'messaging_chatbot_referral_processing',
  'messaging_fsto',
  'messaging_report_wallet_address',
  'messaging_address_scoring',
  'messaging_attachments',
  'messaging_attachment_sends',
  'messaging_request_management',
  'messaging_xmtp_rn_sdk',
  'messaging_subscription_discovery',
  'messaging_inbox_subscription_discovery',
  'messaging_xmtp_rn_sdk_listener',
  'messaging_open_frames',
  'messaging_xmtp_rn_conversation_status',

  // send
  'global_trays_on_qr_code_scan',
  'nft_grid_views',
  'collections_grid_view',
  'aggregated_oe721s',
  'gas_tracker',
  'standardize_errors_dex',
  'staking',
  'delegator_staking',
  'earn_balance',
  'pending_collectible',
  'display_cbpay_buy_button_navbar',
  'gift_links2',
  'pending_utxo_aware_balances',
  'utxo_pending_tx_tray',
  'utxo_tx_cancel',
  'eth_tx_cancel',
  'utxo_tx_speed_up',
  'eth_tx_speed_up',
  'top_up_for_all_supported_assets',
  'top_up_v2',
  'handle_wallet_not_found_error',
  'recent_recipients_tab_v3',
  'send_blockchain_all',
  'send_predictive_search',
  'address_poisoning_check',
  'alternate_address_poisoning_check',
  'send_spam_token_warning',
  'warn_sends_to_known_contracts',
  'real_time_swaps',
  'real_time_swaps_pay_with_usdc',

  // send via link
  'send_via_link',
  'send_via_link_qr_code',
  'send_via_link_adp_banner',
  'send_via_link_send_input_option',
  'send_via_link_config_per_asset',
  'linkdrop_send_via_link_transfer_limits',
  'send_via_link_pano_receive',

  // minting
  'dapp_mint_reservoir',
  'dapp_mint_native',

  'send_flow_pfp',
  'send_confirmation_gasless_upsell',
  'send_success_gasless_upsell',
  'address_book_send',
  'backend_driven_gasless_eligibility',
  'claim_links_native_assets_optimism_arbitrum',
  'claim_links_erc20_assets',

  // receive
  'utxo_address_observability',
  'nft_grid_increased_page_size',
  'nft_tab_sorting',
  'mesh_connect',
  // assets
  'asset_metadata_v4_migration',
  'solana_websocket_nudges',
  // tx history
  'human_readable_transactions_ui',
  'tx_history_gasless_upsell',
  'filter_poisonous_transactions',
  // accounts
  'onboarding_accounts_guardrail',
  'landing_accounts_guardrail',
  'mnemonic_account_consistency_check',
  'import_mnemonic_warning',
  // wallet groups
  'wallet_group_balances',
  // address history
  'balance_fetching_throttle',
  'address_history_transactions',
  'transactions_pagination',
  // payments mode
  'payments_mode',
  'onboard_default_payments_mode',
  'payments_mode_browser',
  'payments_mode_announcements',
  'app_mode_notification_preferences',
  'virtual_account_number',
  'virtual_account_number_microdeposits',
  'virtual_account_number_us',
  'virtual_account_number_global',

  // referral program
  'referral_claim_cta',

  // safety
  'address_scanner',
  'explore_stake_v2',

  // onboarding import flow
  'rp_secure_indicator',
  'guided_import_paths',
  // onboarding create flow
  'instant_onboarding_did_claim_takeover',
  'onboarding_buy_bonus',
  'upsell_announcement_banner',
  'restore_wallet_v2',
  // utxos
  'utxo_transaction_blockheight_syncing',
  'utxo_script_from_transaction',
  // message scanner
  'message_scanner',
  // transaction scanner
  'transaction_preview_solana',
  'seaport_warning',
  'fe_tracing',
  'nft_nux_v2',
  'notification_hub_library',
  'nft_ticketing',
  'streaks_v2',
  // cashout flow
  'cashout_experience',
  'cashout_asset_details',
  'cashout_international',
  'cashout_international_payments_mode',
  'cashout_retail_fiat_account_expansion',
  'cashout_erc20_to_ach_expansion',
  'cashout_assets_v2',
  'cashout_providers_v2',
  'cashout_usdc_instant_ach',
  'cashout_sponsored',
  'quests_intake_card',
  'delete_invalid_accounts_recovery',
  'account_limit_increase',
  'wallet_group_limit_increase',

  // wallet app re-architecture
  'wallet_app_architecture',
  'wallet_app_architecture_messaging',

  // global search
  'global_search',
  'global_search_recent_searches',
  'global_search_settings',
  'global_search_typeahead_search',
  'global_search_using_wac',
  'global_search_using_wac_panorama',
  'global_search_using_nlp',

  // global search web
  'global_search_web',

  // global tray provider
  'global_tray_provider',
  'default_virtual_tray',

  'eth_denver_2024',

  // language setting
  'language_setting',

  // walletlink
  'walletlink_gns_migration',

  'hide_profile_assets',

  'coinbase_connect_wrss_sync',

  'walletlink_modal_v2',

  // solana
  'solana_transaction_priority_fee',

  'user_events_action_service',

  // txn complete opt-in notifs
  'txn_complete_notif_optin',

  // public key export tax promos
  'coin_tracker_promo',
  'crypto_tax_calculator_promo',

  // dapp linkout from extension
  'dapp_linkout',

  'dapp_datadog_synthetics_page',

  'dapp_assets_filters_revamp',

  'nux_eth_on_base',

  // Blocked DNS Screen
  'blocked_dns_screen',

  // SCW
  'scw_mainnet_chains',
  'preference_all_smart_wallet',
  'scw_display_cbpay_buy_button_navbar',
  'scw_swap',
  'scw_send_blockchain_all',
  'scw_add_wallet',
  'scw_app_paymasters',
  'scw_sepolia_paymaster',
  'scw_cloud_paymasters',
  'scw_onboarding',
  'scw_buy',
  'scw_extension_option',
  'scw_walletlink_option',
  'scw_magic_link',
  'scw_session_keys',
  'scw_onboarding_fund_wallet',
  'scw_skip_transaction_confirmation',
  'switch_to_scw_v2',
  'switch_to_ext_v2',
  'scw_share_user_id_with_pano',
  'scw_cross_device_import',
  'scw_txl_full_screen_disclaimer',

  // session keys
  'scw_session_keys',

  // Post-Onboarding UX
  'scw_onboarding_close',

  // whether backend supports sign in with ethereum for scw accounts
  'scw_sign_in_with_ethereum_auth',

  // SCW chains
  'scw_chain_arbitrum',
  'scw_chain_avalanche',
  'scw_chain_base',
  'scw_chain_bsc',
  'scw_chain_mainnet',
  'scw_chain_optimism',
  'scw_chain_polygon',
  'scw_chain_zora',

  // SCW Recovery
  'scw_recovery',
  'scw_replays',
  'scw_retries',
  'scw_recovery_promotional_banner',
  'scw_auto_switch_payment_method',

  // Wallet Providers (Pano)
  'wallet_provider_coinbase_wallet',
  'wallet_provider_phantom',
  'wallet_provider_walletconnect',
  'wallet_provider_mipd', // all injected providers that support eip 6963
  'wallet_dapp',

  // SCW RN
  'scw_rn_import',
  'scw_rn_payments',

  // native storage logging
  'native_storage_logging',

  // auth gate root stack
  'auth_gate_root_stack',

  // Exchange rate
  'exchange_rate_override',

  // Panorama dapps page
  'dapps_page',

  // upsell framework
  'upsell_framework_wallet',

  // User Interests Quiz
  'user_interests_quiz',

  // Swap
  'swap_share',
  'swap_asset_ribbon',
  'cross_chain_swap',
  'cross_chain_swap_bridge_redirect',
  'swap_mode_best',
  'mev_protection',
  'swap_coinbase_fee',
  'cross_chain_swap_slippage',

  // Mnemonic salt recovery
  'mnemonic_salt_recovery',

  // wallet app re-design aka app arch v2
  //
  'wallet_redesign_miami_vice',
  'wallet_redesign_payments_tab',
  'wallet_redesign_browser_trending_dapps',
  'wallet_redesign_top_level_messaging_tab',
  'wallet_redesign_miami_vice_theme_colors',
  'wallet_redesign_fsto',
  'wallet_redesign_transactions_tab',
  'wallet_view_profile',
  'wallet_redesign_avatars',
  'wallet_redesign_new_avatars',
  'wallet_redesign_miami_button',
  'wallet_hide_no_quote_with_low_balance',

  'connect_to_coinbase',
  'wallet_tab_long_press',
  'quests',
  'add_web3_starter_kit_fund_action',
  'ocs_coffee_days',
  'tron_usdt_receive',
  'introducing_smart_wallet_mint',

  // explore
  'explore_pages',

  // social
  'social_feed',
  'social_feed_pano',
  'social_native_mint',
  'social_mint_explore',
  'social_create_mint',

  'explore_on_home_redesign',

  // watchlist
  'watchlist_notif_optin',
  'add_to_watchlist_tray',
  'asset_details_watch_button',

  // tap-to-pay
  'wallet_tap_to_pay',
  'wallet_tap_to_pay_receiver',
  'wallet_tap_to_pay_sender',
  'wallet_tap_to_pay_v2',
  'wallet_tap_to_pay_v2_merchant',
  'wallet_tap_to_pay_v2_customer',

  // warm welcome animation
  'warm_welcome',

  // ADP
  'adp_actions_new_layout',

  // assets percentage change
  'assets_percentage_change',
  'mint_by_credit_card',

  // gallery
  'gallery_page',

  // wallet card
  'wallet_card',

  // wallet mobile homepage redesign for search
  'explore_search_refresh',

  'ripio_payment_method_screen_upsell',

  // deeplinking v2
  'deeplinks_v2_delegation',
  'deeplinks_v2_branch',

  // sprig
  'sprig_web',

  'referral',

  // pano swap disconnected state
  'pano_swap_disconnected_state',

  // aleph crecimiento popup event
  'aleph_crecimiento_popup_event',

  // mint feed
  'mint_feed',

  // social trending swap
  'social_trending_swap',

  // composer actions
  'composer_actions',

  // social trending mint
  'social_trending_mint',

  // tx confirmation rearchitecture
  'tx_confirmation_rearchitecture_send',
  'tx_confirmation_rearchitecture_swap',
  'tx_confirmation_rearchitecture_bridge',
  'tx_confirmation_rearchitecture_web3',
  'wallets_rn_filter_network',
  // receive
  'receive',
  // send NFT
  'send_nft',

  // hide unhide assets and nft
  'hide_unhide_assets',

  // sign out flow v2
  'signout_v2',

  'linkdrop_v3_12',

  // DeFi tab
  'defi_tab_all_networks_rn',

  // trading activity on adp
  'trading_activity_adp',

  'deposit_ui',
] as const;

export type FeatureName = (typeof featureNames)[number];

/* eslint-disable camelcase */
export const featureConfigs: DeepReadonly<Record<FeatureName, FeatureConfig>> = {
  switch_to_scw_v2: {
    killSwitchName: 'kill_switch_to_scw_v2',
    modes: [],
  },
  switch_to_ext_v2: {
    killSwitchName: 'kill_switch_to_ext_v2',
    modes: [],
  },
  scw_app_paymasters: {
    killSwitchName: 'kill_scw_app_paymasters',
    modes: [],
  },
  scw_cloud_paymasters: {
    killSwitchName: 'kill_scw_cloud_paymasters',
    modes: [],
  },
  scw_sepolia_paymaster: {
    killSwitchName: 'kill_scw_sepolia_paymaster',
    modes: [],
  },
  scw_buy: {
    killSwitchName: 'enable_scw_buy_button',
    modes: [],
  },
  scw_mainnet_chains: {
    killSwitchName: 'kill_scw_mainnet_chains',
    modes: [],
  },
  scw_display_cbpay_buy_button_navbar: {
    killSwitchName: 'kill_scw_display_cbpay_buy_button_navbar',
    modes: ['superapp'],
  },
  scw_swap: {
    killSwitchName: 'kill_scw_swap',
    modes: ['superapp'],
  },
  scw_send_blockchain_all: {
    killSwitchName: 'kill_scw_send_blockchain_all',
    modes: ['superapp'],
  },
  scw_add_wallet: {
    killSwitchName: 'kill_scw_add_wallet',
    modes: ['superapp'],
  },
  scw_sign_in_with_ethereum_auth: {
    killSwitchName: 'kill_scw_sign_in_with_ethereum_auth',
    modes: [],
  },
  scw_onboarding_fund_wallet: {
    killSwitchName: 'enable_scw_onboarding_fund_wallet',
    modes: [],
    experiments: {
      web: experiments.jul_2024_scw_onboarding_fund_wallet,
    },
  },
  scw_share_user_id_with_pano: {
    killSwitchName: 'kill_scw_share_user_id_with_pano',
    modes: [],
  },
  did_settings_claim_for_nonprimary_wallet_groups: {
    experiments: {
      android: experiments.apr_2023_did_ma_android,
      extension: experiments.apr_2023_did_ma_extension,
      ios: experiments.apr_2023_did_ma_ios,
    },
    modes: ['superapp'],
  },
  save_secret_confirmation_on_signout: {
    accountTypes: [MNEMONIC, PRIVATE_KEY],
    modes: ['superapp', 'payments'],
  },
  utxo_announcement_banner: {
    experiments: {
      android: experiments.may_2023_utxo_banner_android,
      ios: experiments.may_2023_utxo_banner_ios,
      extension: experiments.mar_2023_utxo_extension,
    },
    killSwitchName: 'kill_utxo_announcement_banner',
    modes: ['superapp'],
  },
  export_private_key: {
    killSwitchName: 'enable_export_private_key',
    modes: ['superapp'],
  },
  account_backup_reminders: {
    killSwitchName: 'kill_account_backup_reminders',
    modes: ['superapp', 'payments'],
  },
  eoa_approval_fraud_warning: {
    killSwitchName: 'kill_eoa_approval_fraud_warning',
    modes: ['superapp'],
  },
  dapp_tx_confirmation_scam_warning: {
    killSwitchName: 'kill_dapp_tx_confirmation_scam_warning',
    modes: ['superapp'],
  },
  assets_tab_nux_cards: {
    killSwitchName: 'enable_assets_tab_nux_cards',
    modes: ['superapp'],
  },
  fund_and_swap_on_explore: {
    experiments: {
      android: experiments.june_2023_fund_and_swap_on_explore,
      ios: experiments.june_2023_fund_and_swap_on_explore,
    },
    killSwitchName: 'enable_fund_and_swap_on_explore',
    modes: ['superapp'],
  },
  browser_tab_screenshot: {
    killSwitchName: 'kill_browser_tab_screenshot',
    modes: ['superapp'],
  },
  browser_cache_clear: {
    killSwitchName: 'kill_browser_cache_clear',
    modes: ['superapp'],
  },
  nux_nft_incentive_entry_modal: {
    killSwitchName: 'enable_nux_nft_incentive_entry_modal',
    modes: ['superapp'],
  },
  browser_nux_tray: {
    killSwitchName: 'enable_browser_nux_tray',
    modes: ['superapp', 'payments'],
  },
  base_browser_nux_tray: {
    killSwitchName: 'enable_base_browser_nux_tray',
    modes: ['superapp', 'payments'],
  },
  instant_funding_flow: {
    killSwitchName: 'enable_instant_funding_flow',
    modes: ['superapp', 'payments'],
  },
  nft_pfp: {
    killSwitchName: 'kill_nft_pfp',
    experiments: {
      ios: experiments.jan_2023_nft_pfp,
      android: experiments.jan_2023_nft_pfp,
      extension: experiments.jan_2023_nft_pfp,
    },
    modes: ['superapp', 'payments'],
  },
  wallet_pfp: {
    killSwitchName: 'enable_wallet_pfp',
    experiments: {
      web: experiments.aug_2024_wallet_pfp,
    },
    modes: ['superapp', 'payments'],
  },
  activation_mint_dot_fun_collections: {
    killSwitchName: 'kill_mint_dot_fun_collections',
    modes: ['superapp'],
  },
  mint_streak_collection_gtm: {
    killSwitchName: 'enable_mint_streak_collection_gtm',
    modes: ['superapp'],
  },
  onramp_last_used_provider: {
    killSwitchName: 'enable_onramp_last_used_provider',
    modes: ['superapp', 'payments'],
  },
  cbpay_provider_interstitial: {
    killSwitchName: 'enable_cbpay_provider_interstitial',
    modes: ['superapp', 'payments'],
  },
  activation_cb_nft_collections: {
    killSwitchName: 'kill_cb_nft_collections',
    modes: ['superapp'],
  },
  activation_trending_collections_tab: {
    killSwitchName: 'kill_trending_collections_tab',
    modes: ['superapp'],
  },
  explore_mint_dot_fun_collections: {
    killSwitchName: 'kill_explore_mint_dot_fun_collections',
    modes: ['superapp'],
  },
  explore_cb_nft_collections: {
    killSwitchName: 'kill_explore_cb_nft_collections',
    modes: ['superapp'],
  },
  explore_trending_collections_tab: {
    killSwitchName: 'kill_explore_trending_collections_tab',
    modes: ['superapp'],
  },
  explore_partner_drop: {
    killSwitchName: 'kill_switch_onchain_summer_upcoming_drops',
    modes: ['superapp'],
  },
  explore_partner_drop_v2: {
    killSwitchName: 'kill_switch_explore_partner_drops',
    modes: ['superapp'],
  },
  messaging_payment_request: {
    killSwitchName: 'kill_messaging_payment_request',
    modes: ['superapp'],
  },
  messaging_mute_conversation: {
    killSwitchName: 'kill_messaging_mute_conversation',
    modes: ['superapp'],
  },
  messaging_subscribe: {
    killSwitchName: 'kill_messaging_subscribe',
    modes: ['superapp'],
  },
  messaging_suggestions: {
    killSwitchName: 'kill_messaging_suggestions',
    experiments: {
      ios: experiments.sep_2023_messaging_suggestions,
      android: experiments.sep_2023_messaging_suggestions,
      extension: experiments.sep_2023_messaging_suggestions,
    },
    modes: ['superapp'],
  },
  messaging_open_frames: {
    killSwitchName: 'kill_messaging_open_frames',
    experiments: {
      ios: experiments.mar_2024_open_frames_in_messaging,
      android: experiments.mar_2024_open_frames_in_messaging,
    },
    modes: ['superapp'],
  },
  messaging_report_wallet_address: {
    killSwitchName: 'kill_messaging_report_wallet_address',
    modes: ['superapp'],
  },
  messaging_address_scoring: {
    killSwitchName: 'kill_messaging_address_scoring',
    modes: ['superapp'],
  },
  messaging_chatbot_referral_processing: {
    killSwitchName: 'kill_messaging_chatbot_referral_processing',
    modes: ['superapp'],
  },
  messaging_fsto: {
    killSwitchName: 'kill_messaging_fsto',
    modes: ['superapp'],
  },
  messaging_subscription_discovery: {
    killSwitchName: 'kill_messaging_subscription_discovery',
    modes: ['superapp'],
  },
  messaging_inbox_subscription_discovery: {
    killSwitchName: 'kill_messaging_inbox_subscription_discovery',
    modes: ['superapp'],
  },
  explore_upcoming_drop_notification_link: {
    killSwitchName: 'kill_explore_upcoming_drop_notification_link',
    modes: ['superapp'],
  },
  global_trays_on_qr_code_scan: {
    killSwitchName: 'kill_global_trays_on_qr_code_scan',
    experiments: {
      ios: experiments.aug_2023_wallet_rn_global_trays_on_qr_code_scan,
      android: experiments.aug_2023_wallet_rn_global_trays_on_qr_code_scan,
    },
    modes: ['superapp', 'payments'],
  },
  transaction_preview_solana: {
    killSwitchName: 'kill_transaction_preview_solana',
    experiments: {
      ios: experiments.mar_2024_transaction_preview_solana,
      android: experiments.mar_2024_transaction_preview_solana,
      extension: experiments.mar_2024_transaction_preview_solana,
    },
    modes: ['superapp', 'payments'],
  },
  handle_wallet_not_found_error: {
    killSwitchName: 'kill_handle_wallet_not_found_error',
    modes: ['superapp', 'payments'],
  },
  nft_grid_views: {
    killSwitchName: 'kill_nft_grid_view',
    modes: ['superapp'],
  },
  collections_grid_view: {
    killSwitchName: 'kill_collections_grid_view',
    modes: ['superapp'],
  },
  aggregated_oe721s: {
    killSwitchName: 'kill_nft_aggregated_oe721s',
    modes: ['superapp'],
  },
  messaging_attachments: {
    killSwitchName: 'kill_messaging_attachments',
    modes: ['superapp'],
  },
  messaging_attachment_sends: {
    killSwitchName: 'kill_messaging_attachment_sends',
    experiments: {
      ios: experiments.nov_2023_message_attachment_upload,
      android: experiments.nov_2023_message_attachment_upload,
    },
    modes: ['superapp'],
  },
  messaging_request_management: {
    killSwitchName: 'kill_request_management',
    modes: ['superapp'],
  },
  messaging_xmtp_rn_sdk: {
    killSwitchName: 'kill_messaging_xmtp_rn_sdk',
    modes: ['superapp'],
  },
  messaging_xmtp_rn_sdk_listener: {
    killSwitchName: 'kill_messaging_xmtp_rn_sdk_listener_v2',
    experiments: {
      ios: experiments.apr_2024_messaging_xmtp_rn_sdk_listener,
      android: experiments.apr_2024_messaging_xmtp_rn_sdk_listener,
    },
    modes: ['superapp'],
  },
  messaging_xmtp_rn_conversation_status: {
    killSwitchName: 'kill_messaging_xmtp_rn_conversation_status',
    modes: ['superapp'],
  },
  guest_checkout: {
    killSwitchName: 'kill_guest_checkout',
    experiments: {
      ios: experiments.nov_2022_cbpay_guest_checkout,
      android: experiments.nov_2022_cbpay_guest_checkout,
      extension: experiments.nov_2022_cbpay_guest_checkout,
    },
    modes: ['superapp', 'payments'],
  },
  cbpay_sdk: {
    killSwitchName: 'enable_cbpay_sdk',
    modes: ['superapp', 'payments'],
  },
  gas_tracker: {
    killSwitchName: 'kill_gas_tracker',
    experiments: {
      ios: experiments.aug_2023_wallet_rn_gas_tracker,
      android: experiments.aug_2023_wallet_rn_gas_tracker,
    },
    modes: ['superapp'],
  },
  standardize_errors_dex: {
    killSwitchName: 'kill_standardize_errors_dex',
    experiments: {
      ios: experiments.aug_2023_standardize_errors_dex,
      android: experiments.aug_2023_standardize_errors_dex,
      extension: experiments.aug_2023_standardize_errors_dex,
    },
    modes: ['superapp'],
  },
  staking: {
    killSwitchName: 'kill_stake',
    modes: ['superapp'],
  },
  delegator_staking: {
    killSwitchName: 'kill_delegator_staking',
    experiments: {
      extension: experiments.sep_2023_delegator_staking,
    },
    modes: ['superapp'],
  },
  earn_balance: {
    killSwitchName: 'kill_earn_balance',
    modes: ['superapp'],
  },
  pending_collectible: {
    killSwitchName: 'kill_switch_pending_collectible',
    modes: ['superapp'],
  },
  display_cbpay_buy_button_navbar: {
    killSwitchName: 'kill_display_cbpay_buy_button_navbar',
    modes: ['superapp', 'payments'],
  },
  gift_links2: {
    killSwitchName: 'enable_gift_links2',
    modes: ['superapp'],
  },
  utxo_address_observability: {
    killSwitchName: 'enable_address_observability',
    modes: ['superapp'],
  },
  utxo_transaction_blockheight_syncing: {
    killSwitchName: 'kill_utxo_transaction_blockheight_syncing',
    modes: ['superapp'],
  },
  utxo_script_from_transaction: {
    killSwitchName: 'kill_utxo_script_from_transaction',
    modes: ['superapp'],
  },
  nft_grid_increased_page_size: {
    killSwitchName: 'enable_nft_grid_increased_page_size',
    modes: ['superapp'],
  },
  pending_utxo_aware_balances: {
    killSwitchName: 'kill_pending_utxo_aware_balances',
    modes: ['superapp', 'payments'],
  },
  recent_recipients_tab_v3: {
    killSwitchName: 'kill_recent_recipients_tab_v3',
    experiments: {
      ios: experiments.dec_2023_recent_recipients_tab_v3,
      android: experiments.dec_2023_recent_recipients_tab_v3,
      extension: experiments.dec_2023_recent_recipients_tab_v3,
    },
    modes: ['superapp', 'payments'],
  },
  utxo_tx_cancel: {
    killSwitchName: 'kill_utxo_tx_cancel',
    modes: ['superapp', 'payments'],
  },
  utxo_tx_speed_up: {
    killSwitchName: 'kill_utxo_tx_speed_up',
    modes: ['superapp', 'payments'],
  },
  eth_tx_cancel: {
    killSwitchName: 'kill_eth_tx_cancel',
    modes: ['superapp', 'payments'],
  },
  eth_tx_speed_up: {
    killSwitchName: 'kill_eth_tx_speed_up',
    modes: ['superapp', 'payments'],
  },
  utxo_pending_tx_tray: {
    killSwitchName: 'kill_utxo_pending_tx_tray',
    modes: ['superapp', 'payments'],
  },
  nft_tab_sorting: {
    killSwitchName: 'enable_nft_tab_sorting',
    modes: ['superapp'],
  },
  mesh_connect: {
    killSwitchName: 'kill_mesh_connect',
    experiments: {
      ios: experiments.jun_2024_mesh_connect,
      android: experiments.jun_2024_mesh_connect,
    },
    modes: ['superapp', 'payments'],
  },
  asset_metadata_v4_migration: {
    killSwitchName: 'kill_asset_metadata_v4_migration',
    experiments: {
      ios: experiments.feb_2024_asset_metadata_v4_endpoint,
      android: experiments.feb_2024_asset_metadata_v4_endpoint,
      extension: experiments.feb_2024_asset_metadata_v4_endpoint,
    },
    modes: ['superapp'],
  },
  human_readable_transactions_ui: {
    killSwitchName: 'enable_human_readable_transactions_ui',
    modes: ['superapp', 'payments'],
  },
  onboarding_accounts_guardrail: {
    killSwitchName: 'enable_onboarding_accounts_guardrail',
    modes: ['superapp', 'payments'],
  },
  landing_accounts_guardrail: {
    // prior to March 2024, these were enable switches
    killSwitchName: 'kill_landing_accounts_guardrail',
    modes: ['superapp', 'payments'],
  },
  web3_starter_kit_on_asset_list: {
    killSwitchName: 'enable_web3_starter_kit_on_asset_list',
    modes: ['superapp'],
  },
  initialize_local_currency: {
    killSwitchName: 'enable_initialize_local_currency',
    modes: ['superapp', 'payments'],
  },
  onramp_currency_selector: {
    killSwitchName: 'enable_onramp_currency_selector',
    modes: ['superapp', 'payments'],
  },
  onramp_swap_assets: {
    killSwitchName: 'enable_onramp_swap_assets',
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.apr_2024_wallet_onramp_swap_assets,
      android: experiments.apr_2024_wallet_onramp_swap_assets,
    },
  },
  onramp_ocb_deeplink: {
    killSwitchName: 'enable_onramp_ocb_deeplink',
    modes: ['superapp', 'payments'],
  },
  onramp_native_buy: {
    killSwitchName: 'enable_onramp_native_buy',
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.may_2024_wallet_onramp_native_buy,
      android: experiments.may_2024_wallet_onramp_native_buy,
    },
  },
  scw_onramp_native_buy: {
    killSwitchName: 'enable_scw_onramp_native_buy',
    experiments: {
      web: experiments.sep_2024_scw_native_buy,
    },
    modes: [],
  },
  onramp_cb_native_buy: {
    killSwitchName: 'enable_onramp_cb_native_buy',
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.jul_2024_onramp_cb_native_buy,
      android: experiments.jul_2024_onramp_cb_native_buy,
    },
  },
  onramp_unsupported_payment_method_tray: {
    killSwitchName: 'enable_onramp_unsupported_payment_method_tray',
    modes: ['superapp', 'payments'],
  },
  onramp_mgx_unconnected_state: {
    killSwitchName: 'enable_onramp_mgx_unconnected_state',
    modes: ['superapp', 'payments'],
  },
  onramp_confirmation_screen: {
    killSwitchName: 'enable_onramp_confirmation_screen',
    modes: ['superapp', 'payments'],
  },
  onramp_stripe_integration: {
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.jul_2024_wallet_stripe_onramp_integration,
      android: experiments.jul_2024_wallet_stripe_onramp_integration,
    },
  },
  nft_incentive_with_usdc: {
    killSwitchName: 'enable_nft_incentive_with_usdc',
    experiments: {
      ios: experiments.nov_2023_nft_incentive_with_usdc,
      android: experiments.nov_2023_nft_incentive_with_usdc,
    },
    modes: ['superapp'],
  },
  authenticated_webview_wsst_flow: {
    killSwitchName: 'kill_authenticated_webview_wsst_flow',
    modes: ['superapp', 'payments'],
  },
  mnemonic_account_consistency_check: {
    killSwitchName: 'enable_mnemonic_account_consistency_check',
    modes: ['superapp', 'payments'],
  },
  balance_fetching_throttle: {
    killSwitchName: 'enable_balance_fetching_throttle',
    modes: ['superapp', 'payments'],
  },
  payments_mode: {
    killSwitchName: 'kill_payments_mode',
    modes: ['superapp', 'payments'],
  },
  onboard_default_payments_mode: {
    killSwitchName: 'kill_onboard_default_payments_mode',
    modes: ['superapp', 'payments'],
  },
  address_history_transactions: {
    killSwitchName: 'kill_address_history_transactions',
    modes: ['superapp', 'payments'],
  },
  solana_websocket_nudges: {
    killSwitchName: 'kill_solana_websocket_nudges',
    modes: ['superapp', 'payments'],
  },
  wallet_group_balances: {
    killSwitchName: 'kill_wallet_group_balances',
    modes: ['superapp', 'payments'],
  },
  human_readable_transactions: {
    killSwitchName: 'enable_human_readable_transactions_v2',
    experiments: {
      ios: experiments.sep_2023_human_readable_transactions_client,
      android: experiments.sep_2023_human_readable_transactions_client,
      extension: experiments.sep_2023_human_readable_transactions_client,
    },
    modes: ['superapp', 'payments'],
  },
  top_up_for_all_supported_assets: {
    killSwitchName: 'kill_top_up_for_all_supported_assets',
    experiments: {
      ios: experiments.oct_2023_top_up_all_supported_assets_rn,
      android: experiments.oct_2023_top_up_all_supported_assets_rn,
    },
    modes: ['superapp', 'payments'],
  },
  address_scanner: {
    killSwitchName: 'kill_address_scanner',
    experiments: {
      ios: experiments.oct_2023_address_scan_rn,
      android: experiments.oct_2023_address_scan_rn,
      extension: experiments.oct_2023_address_scan_extension,
    },
    modes: ['superapp', 'payments'],
  },
  show_notifications_permissions_post_onboarding: {
    killSwitchName: 'kill_show_notifications_permissions_post_onboarding',
    experiments: {
      ios: experiments.oct_2023_show_notifications_tray_post_onboarding_rn,
      android: experiments.oct_2023_show_notifications_tray_post_onboarding_rn,
      extension: experiments.oct_2023_show_notifications_tray_post_onboarding_rn,
    },
    modes: ['superapp', 'payments'],
  },
  instant_onboarding_did_claim_takeover: {
    killSwitchName: 'kill_instant_onboarding_did_claim_takeover',
    modes: ['superapp'],
  },
  onboarding_buy_bonus: {
    killSwitchName: 'kill_onboarding_buy_bonus',
    modes: ['payments', 'superapp'],
  },
  scw_rn_import: {
    killSwitchName: 'kill_scw_rn_import',
    experiments: {
      ios: experiments.jul_2024_scw_import_rn,
      android: experiments.jul_2024_scw_import_rn,
    },
    modes: ['superapp', 'payments'],
  },
  scw_rn_payments: {
    killSwitchName: 'kill_scw_rn_payments',
    modes: ['superapp', 'payments'],
  },
  scw_cross_device_import: {
    killSwitchName: 'kill_scw_cross_device_import',
    modes: ['superapp', 'payments'],
  },
  scw_txl_full_screen_disclaimer: {
    killSwitchName: 'kill_scw_txl_full_screen_disclaimer',
    modes: [],
  },
  upsell_announcement_banner: {
    killSwitchName: 'kill_upsell_announcement_banner',
    modes: ['superapp', 'payments'],
  },
  restore_wallet_v2: {
    experiments: {
      ios: experiments.jul_2024_restore_wallet_v2_rn,
      android: experiments.jul_2024_restore_wallet_v2_rn,
    },
    modes: ['superapp', 'payments'],
  },
  rp_secure_indicator: {
    killSwitchName: 'kill_rp_secure_indicator',
    experiments: {
      ios: experiments.nov_2023_rp_secure_indicator,
      android: experiments.nov_2023_rp_secure_indicator,
    },
    modes: ['superapp'],
  },
  guided_import_paths: {
    killSwitchName: 'kill_guided_import_paths',
    experiments: {
      ios: experiments.nov_2023_guided_import_paths,
      android: experiments.nov_2023_guided_import_paths,
    },
    modes: ['superapp'],
  },
  explore_stake_v2: {
    experiments: {
      ios: experiments.nov_2023_explore_defi,
      android: experiments.nov_2023_explore_defi,
    },
    modes: ['superapp'],
  },
  wallet_settings_hub_notif_optin: {
    experiments: {
      android: experiments.oct_2023_wallet_settings_hub_notif_optin_rn,
      ios: experiments.oct_2023_wallet_settings_hub_notif_optin_rn,
    },
    killSwitchName: 'kill_wallet_settings_hub_notif_optin',
    modes: ['superapp'],
  },
  load_existing_browser_tab: {
    killSwitchName: 'kill_load_existing_browser_tab',
    modes: ['superapp', 'payments'],
  },
  message_scanner: {
    killSwitchName: 'kill_message_scan_v2',
    experiments: {
      ios: experiments.sep_2023_message_scanner_ios,
      android: experiments.sep_2023_message_scanner_android,
      extension: experiments.sep_2023_message_scanner_extension,
    },
    modes: ['payments', 'superapp'],
  },
  seaport_warning: {
    killSwitchName: 'kill_message_scan_seaport_warning',
    modes: ['superapp'],
  },
  wallet_extension_promotion_announcement: {
    experiments: {
      android: experiments.nov_2023_wallet_extension_promotion_announcement,
      ios: experiments.nov_2023_wallet_extension_promotion_announcement,
    },
    killSwitchName: 'kill_wallet_extension_promotion_announcement',
    modes: ['superapp'],
  },
  fe_tracing: {
    killSwitchName: 'kill_fe_tracing',
    modes: ['superapp'],
  },
  send_blockchain_all: {
    killSwitchName: 'kill_send_blockchain_all',
    modes: ['superapp'],
  },
  send_via_link: {
    killSwitchName: 'kill_dynamic_send_via_link',
    experiments: {
      ios: experiments.nov_2023_send_via_link_rn,
      android: experiments.nov_2023_send_via_link_rn,
    },
    modes: ['superapp', 'payments'],
  },
  send_via_link_config_per_asset: {
    killSwitchName: 'kill_send_via_link_config_per_asset',
    modes: ['superapp', 'payments'],
  },
  send_via_link_adp_banner: {
    killSwitchName: 'kill_dynamic_send_via_link_adp_banner',
    modes: ['superapp', 'payments'],
  },
  send_via_link_qr_code: {
    killSwitchName: 'kill_send_via_link_qr_code',
    modes: ['superapp', 'payments'],
  },
  send_via_link_send_input_option: {
    killSwitchName: 'kill_dynamic_send_via_link_send_input_option',
    experiments: {
      ios: experiments.dec_2023_send_via_link_send_input_option,
      android: experiments.dec_2023_send_via_link_send_input_option,
    },
    modes: ['superapp', 'payments'],
  },
  linkdrop_send_via_link_transfer_limits: {
    killSwitchName: 'enable_linkdrop_send_via_link_transfer_limits',
    modes: ['superapp', 'payments'],
  },
  send_predictive_search: {
    killSwitchName: 'kill_send_predictive_search',
    modes: ['superapp', 'payments'],
  },
  usdc_rewards_v2: {
    killSwitchName: 'kill_usdc_rewards',
    modes: ['superapp', 'payments'],
  },
  usdc_rewards_summary: {
    killSwitchName: 'kill_usdc_rewards_summary',
    modes: ['superapp', 'payments'],
  },
  bridge_base_upsell: {
    killSwitchName: 'kill_bridge_base_upsell',
    modes: ['superapp'],
  },
  nft_nux_v2: {
    killSwitchName: 'kill_nft_nux_v2',
    modes: ['superapp'],
  },
  skip_restore_wallet: {
    killSwitchName: 'kill_skip_restore_wallet',
    modes: ['superapp', 'payments'],
  },
  nft_ticketing: {
    killSwitchName: 'kill_nft_ticketing',
    experiments: {
      ios: experiments.dec_2023_nft_ticketing,
      android: experiments.dec_2023_nft_ticketing,
    },
    modes: ['superapp'],
  },
  send_flow_pfp: {
    killSwitchName: 'kill_send_flow_pfp',
    modes: ['superapp', 'payments'],
  },
  streaks_v2: {
    killSwitchName: 'kill_streaks_v2',
    experiments: {
      ios: experiments.dec_2023_streaks_v2,
      android: experiments.dec_2023_streaks_v2,
    },
    modes: ['superapp'],
  },
  cashout_experience: {
    accountTypes: [MNEMONIC, PRIVATE_KEY, DAPP_PROVIDER],
    killSwitchName: 'enable_cashout',
    experiments: {
      ios: experiments.q1_2024_cashout_us,
      android: experiments.q1_2024_cashout_us,
    },
    modes: ['superapp', 'payments'],
  },
  cashout_international: {
    killSwitchName: 'enable_cashout_international_mvp',
    experiments: {
      ios: experiments.q2_2024_cashout_intl_providers,
      android: experiments.q2_2024_cashout_intl_providers,
    },
    modes: ['superapp'],
  },
  cashout_international_payments_mode: {
    killSwitchName: 'enable_cashout_international_payments_mode',
    experiments: {
      ios: experiments.q2_2024_cashout_intl_providers,
      android: experiments.q2_2024_cashout_intl_providers,
    },
    modes: ['payments'],
  },
  cashout_retail_fiat_account_expansion: {
    killSwitchName: 'enable_cashout_retail_fiat_account_expansion',
    experiments: {
      ios: experiments.q2_cashout_retail_fiat_account_expansion,
      android: experiments.q2_cashout_retail_fiat_account_expansion,
    },
    modes: ['superapp', 'payments'],
  },
  cashout_asset_details: {
    accountTypes: [MNEMONIC, PRIVATE_KEY],
    killSwitchName: 'kill_cashout_asset_details',
    modes: ['superapp', 'payments'],
  },
  cashout_erc20_to_ach_expansion: {
    killSwitchName: 'kill_cashout_erc20_to_ach',
    experiments: {
      ios: experiments.q3_erc20_to_ach_expansion,
      android: experiments.q3_erc20_to_ach_expansion,
    },
    modes: ['superapp', 'payments'],
  },
  cashout_assets_v2: {
    killSwitchName: 'kill_cashout_assets_v2',
    experiments: {
      ios: experiments.q3_cashout_supported_assets_v2,
      android: experiments.q3_cashout_supported_assets_v2,
    },
    modes: ['superapp', 'payments'],
  },
  cashout_providers_v2: {
    killSwitchName: 'enable_cashout_providers_v2',
    modes: ['superapp', 'payments'],
  },
  cashout_usdc_instant_ach: {
    experiments: {
      ios: experiments.q3_usdc_instant_ach,
      android: experiments.q3_usdc_instant_ach,
    },
    modes: ['superapp', 'payments'],
  },
  cashout_sponsored: {
    killSwitchName: 'kill_sponsored_cashout',
    experiments: {
      ios: experiments.q3_sponsored_cashout,
      android: experiments.q3_sponsored_cashout,
    },
    modes: ['superapp', 'payments'],
  },
  notification_hub_library: {
    experiments: {
      ios: experiments.mar_2024_wallet_notif_library,
      android: experiments.mar_2024_wallet_notif_library,
    },
    killSwitchName: 'kill_notification_hub_library',
    modes: ['superapp'],
  },
  quests_intake_card: {
    experiments: {
      ios: experiments.dec_2023_wallet_quests_intake_card,
      android: experiments.dec_2023_wallet_quests_intake_card,
    },
    modes: ['superapp'],
  },
  delete_invalid_accounts_recovery: {
    killSwitchName: 'kill_delete_invalid_accounts_recovery',
    modes: ['superapp', 'payments'],
  },
  account_limit_increase: {
    killSwitchName: 'kill_account_limit_increase',
    experiments: {
      ios: experiments.dec_2023_account_limit_increase,
      android: experiments.dec_2023_account_limit_increase,
      extension: experiments.dec_2023_account_limit_increase,
    },
    modes: ['superapp'],
  },
  wallet_group_limit_increase: {
    killSwitchName: 'kill_wallet_group_limit_increase',
    // intended it's using the same experiment as account_limit_increase
    experiments: {
      ios: experiments.dec_2023_account_limit_increase,
      android: experiments.dec_2023_account_limit_increase,
      extension: experiments.dec_2023_account_limit_increase,
    },
    modes: ['superapp'],
  },
  wallet_app_architecture: {
    experiments: {
      ios: experiments.jan_2024_wallet_app_architecture,
      android: experiments.jan_2024_wallet_app_architecture,
    },
    killSwitchName: 'kill_wallet_app_architecture',
    modes: ['superapp', 'payments'],
  },
  send_confirmation_gasless_upsell: {
    experiments: {
      ios: experiments.jan_2024_gasless_send_confirmation_upsell_rn,
      android: experiments.jan_2024_gasless_send_confirmation_upsell_rn,
    },
    killSwitchName: 'kill_send_confirmation_gasless_upsell',
    modes: ['superapp', 'payments'],
  },
  send_success_gasless_upsell: {
    experiments: {
      ios: experiments.jan_2024_gasless_send_success_upsell_rn,
      android: experiments.jan_2024_gasless_send_success_upsell_rn,
    },
    killSwitchName: 'kill_send_success_gasless_upsell',
    modes: ['superapp', 'payments'],
  },
  tx_history_gasless_upsell: {
    killSwitchName: 'kill_tx_history_gasless_upsell',
    modes: ['superapp', 'payments'],
  },
  global_search: {
    experiments: {
      ios: experiments.jan_2024_wallet_global_search_mobile,
      android: experiments.jan_2024_wallet_global_search_mobile,
    },
    killSwitchName: 'kill_wallet_global_search',
    modes: ['superapp'],
  },
  global_search_using_nlp: {
    killSwitchName: 'kill_wallet_global_search_using_nlp',
    modes: ['superapp'],
  },
  global_search_recent_searches: {
    killSwitchName: 'kill_wallet_global_search_recent_searches',
    modes: ['superapp'],
  },
  global_search_settings: {
    killSwitchName: 'kill_wallet_global_search_settings',
    modes: ['superapp'],
  },
  global_search_typeahead_search: {
    killSwitchName: 'kill_wallet_global_search_typeahead_search',
    modes: ['superapp'],
  },
  global_search_using_wac: {
    experiments: {
      ios: experiments.apr_2024_wallet_global_search_using_wac,
      android: experiments.apr_2024_wallet_global_search_using_wac,
    },
    killSwitchName: 'kill_wallet_global_search_using_wac',
    modes: ['superapp'],
  },
  global_search_using_wac_panorama: {
    killSwitchName: 'kill_wallet_global_search_using_wac',
    modes: ['superapp'],
  },
  global_search_web: {
    killSwitchName: 'kill_global_search',
    modes: ['superapp'],
  },
  receive: {
    killSwitchName: 'kill_receive',
    modes: ['superapp', 'payments'],
  },
  send_nft: {
    killSwitchName: 'kill_send_nft',
    modes: ['superapp', 'payments'],
  },
  eth_denver_2024: {
    killSwitchName: 'enable_eth_denver_2024',
    modes: ['superapp'],
  },
  language_setting: {
    killSwitchName: 'kill_wallet_locale_setting',
    modes: ['superapp', 'payments'],
  },
  warn_sends_to_known_contracts: {
    killSwitchName: 'kill_warn_sends_to_known_contracts',
    modes: ['superapp', 'payments'],
  },
  address_book_send: {
    killSwitchName: 'kill_address_book_send',
    modes: ['superapp', 'payments'],
  },
  walletlink_gns_migration: {
    killSwitchName: 'kill_walletlink_gns_migration',
    experiments: {
      ios: experiments.dec_2023_walletlink_gns_migration,
      android: experiments.dec_2023_walletlink_gns_migration,
    },
    modes: ['superapp'],
  },
  hide_profile_assets: {
    killSwitchName: 'kill_hide_profile_assets',
    modes: ['superapp'],
  },
  backend_driven_gasless_eligibility: {
    killSwitchName: 'kill_backend_driven_gasless_eligibility',
    modes: ['superapp', 'payments'],
  },
  coinbase_connect_wrss_sync: {
    killSwitchName: 'kill_coinbase_connect_wrss_sync',
    modes: ['superapp'],
  },
  top_up_for_web3_transactions: {
    killSwitchName: 'kill_top_up_for_web3_transactions',
    experiments: {
      ios: experiments.feb_2024_top_up_for_web3_transactions,
      android: experiments.feb_2024_top_up_for_web3_transactions,
    },
    modes: ['superapp'],
  },
  claim_links_native_assets_optimism_arbitrum: {
    experiments: {
      ios: experiments.mar_2024_wallet_native_assets_claim_links_optimism_arbitrum,
      android: experiments.mar_2024_wallet_native_assets_claim_links_optimism_arbitrum,
    },
    modes: ['superapp', 'payments'],
  },
  claim_links_erc20_assets: {
    killSwitchName: 'kill_claim_links_erc20_assets',
    modes: ['superapp', 'payments'],
  },
  wallet_app_architecture_messaging: {
    experiments: {
      ios: experiments.mar_2024_wallet_app_architecture_messaging,
      android: experiments.mar_2024_wallet_app_architecture_messaging,
    },
    killSwitchName: 'kill_wallet_app_architecture_messaging',
    modes: ['superapp'],
  },
  solana_transaction_priority_fee: {
    killSwitchName: 'kill_solana_transaction_priority_fee',
    modes: ['superapp'],
  },
  user_events_action_service: {
    killSwitchName: 'kill_user_events_action_service',
    experiments: {
      ios: experiments.mar_2024_user_events_action_service,
      android: experiments.mar_2024_user_events_action_service,
    },
    modes: ['superapp', 'payments'],
  },
  txn_complete_notif_optin: {
    killSwitchName: 'kill_txn_complete_notif_optin',
    experiments: {
      ios: experiments.mar_2024_txn_complete_notif_optin,
      android: experiments.mar_2024_txn_complete_notif_optin,
    },
    modes: ['superapp', 'payments'],
  },
  referral_claim_cta: {
    killSwitchName: 'kill_referral_claim_cta',
    modes: ['superapp', 'payments'],
  },
  coin_tracker_promo: {
    killSwitchName: 'kill_coin_tracker_promo',
    modes: ['superapp'],
  },
  crypto_tax_calculator_promo: {
    killSwitchName: 'kill_crypto_tax_calculator_promo',
    modes: ['superapp'],
  },
  referral: {
    killSwitchName: 'kill_referral',
    modes: ['superapp', 'payments'],
  },
  dapp_linkout: {
    killSwitchName: 'kill_dapp_linkout_v2',
    modes: ['superapp'],
  },
  fiat_ui_copies: {
    killSwitchName: 'kill_fiat_ui_copies',
    modes: ['superapp', 'payments'],
  },
  nux_eth_on_base: {
    experiments: {
      android: experiments.mar_2024_wallet_nux_eth_on_base,
      ios: experiments.mar_2024_wallet_nux_eth_on_base,
    },
    modes: ['superapp'],
  },
  blocked_dns_screen: {
    killSwitchName: 'kill_blocked_dns_screen',
    modes: ['superapp'],
  },
  wallet_provider_coinbase_wallet: {
    killSwitchName: 'kill_wallet_provider_coinbase_wallet',
    modes: ['superapp'],
  },
  wallet_provider_phantom: {
    killSwitchName: 'kill_wallet_provider_phantom',
    modes: ['superapp'],
  },
  wallet_provider_walletconnect: {
    killSwitchName: 'kill_wallet_provider_walletconnect',
    modes: ['superapp'],
  },
  wallet_provider_mipd: {
    killSwitchName: 'kill_wallet_provider_mipd',
    modes: ['superapp'],
  },
  wallet_dapp: {
    killSwitchName: 'kill_wallet',
    modes: ['superapp'],
  },
  native_storage_logging: {
    killSwitchName: 'kill_native_storage_logging',
    modes: ['superapp'],
  },
  // This config is being accessed outside of ExperimentationsProvider. Be wary
  // if considering to add an experiment.
  auth_gate_root_stack: {
    killSwitchName: 'kill_auth_gate_root_stack',
    modes: ['superapp', 'payments'],
  },
  global_tray_provider: {
    killSwitchName: 'enable_global_tray_provider',
    experiments: {
      ios: experiments.mar_2024_wallet_rn_global_tray_provider,
      android: experiments.mar_2024_wallet_rn_global_tray_provider,
    },
    modes: ['superapp', 'payments'],
  },
  default_virtual_tray: {
    killSwitchName: 'enable_default_virtual_tray',
    experiments: {
      ios: experiments.mar_2024_wallet_rn_default_virtual_tray,
      android: experiments.mar_2024_wallet_rn_default_virtual_tray,
    },
    modes: ['superapp', 'payments'],
  },
  exchange_rate_override: {
    killSwitchName: 'kill_exchange_rate_override',
    modes: ['superapp', 'payments'],
  },
  payments_mode_browser: {
    killSwitchName: 'kill_payments_mode_browser',
    modes: ['payments'],
  },
  dapps_page: {
    killSwitchName: 'kill_dapps_page',
    modes: ['superapp'],
  },
  upsell_framework_wallet: {
    killSwitchName: 'kill_upsell_framework_wallet',
    modes: ['superapp'],
  },
  user_interests_quiz: {
    killSwitchName: 'kill_user_interests_quiz',
    experiments: {
      ios: experiments.apr_2024_wallet_rn_user_interests_quiz,
      android: experiments.apr_2024_wallet_rn_user_interests_quiz,
    },
    modes: ['superapp'],
  },
  asset_details_watch_button: {
    killSwitchName: 'kill_asset_details_watch_button',
    modes: ['superapp'],
  },
  swap_share: {
    killSwitchName: 'kill_swap_share_card',
    modes: ['payments', 'superapp'],
  },
  swap_asset_ribbon: {
    killSwitchName: 'kill_swap_asset_ribbon',
    modes: ['payments', 'superapp'],
  },
  mnemonic_salt_recovery: {
    killSwitchName: 'kill_mnemonic_salt_recovery',
    modes: ['payments', 'superapp'],
  },
  top_up_v2: {
    killSwitchName: 'kill_top_up_v2',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_payments_tab: {
    killSwitchName: 'kill_wallet_redesign_payments_tab',
    modes: ['superapp'],
  },
  wallet_redesign_miami_button: {
    killSwitchName: 'kill_wallet_redesign_miami_button',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_browser_trending_dapps: {
    killSwitchName: 'kill_wallet_redesign_browser_trending_dapps',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_top_level_messaging_tab: {
    killSwitchName: 'kill_wallet_redesign_top_level_messaging_tab',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_miami_vice_theme_colors: {
    killSwitchName: 'kill_wallet_redesign_miami_vice_theme_colors',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_fsto: {
    killSwitchName: 'kill_wallet_redesign_fsto',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_transactions_tab: {
    killSwitchName: 'kill_wallet_redesign_transactions_tab',
    modes: ['superapp', 'payments'],
  },
  wallet_view_profile: {
    killSwitchName: 'kill_wallet_view_profile',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_avatars: {
    killSwitchName: 'kill_wallet_redesign_avatars',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_miami_vice: {
    experiments: {
      ios: experiments.apr_2024_wallet_redesign_miami_vice,
      android: experiments.apr_2024_wallet_redesign_miami_vice,
      web: experiments.apr_2024_wallet_redesign_miami_vice_panorama,
    },
    killSwitchName: 'kill_wallet_redesign_miami_vice',
    modes: ['superapp', 'payments'],
  },
  wallet_redesign_new_avatars: {
    experiments: {
      web: experiments.apr_2024_wallet_redesign_miami_vice_panorama,
    },
    killSwitchName: 'kill_wallet_redesign_avatars',
    modes: ['superapp', 'payments'],
  },
  wallet_hide_no_quote_with_low_balance: {
    experiments: {
      web: experiments.aug_2024_wallet_hide_no_quote_with_low_balance,
      android: experiments.aug_2024_wallet_hide_no_quote_with_low_balance,
      ios: experiments.aug_2024_wallet_hide_no_quote_with_low_balance,
    },
    killSwitchName: 'kill_wallet_hide_no_quote_with_low_balance',
    modes: ['superapp', 'payments'],
  },
  filter_poisonous_transactions: {
    killSwitchName: 'kill_filter_poisonous_transactions',
    modes: ['superapp', 'payments'],
  },
  dapp_datadog_synthetics_page: {
    killSwitchName: 'kill_dapp_datadog_synthetics_page',
    modes: ['superapp'],
  },
  address_poisoning_check: {
    killSwitchName: 'enable_address_poisoning_check',
    experiments: {
      ios: experiments.apr_2024_wallet_send_address_poisoning_warning_rn,
      android: experiments.apr_2024_wallet_send_address_poisoning_warning_rn,
      extension: experiments.apr_2024_wallet_send_address_poisoning_warning_extension,
    },
    modes: ['superapp', 'payments'],
  },
  alternate_address_poisoning_check: {
    killSwitchName: 'enable_alternate_address_poisoning_check',
    modes: ['superapp', 'payments'],
  },
  connect_to_coinbase: {
    killSwitchName: 'kill_connect_to_coinbase',
    modes: ['superapp'],
  },
  quests: {
    killSwitchName: 'kill_quests',
    modes: ['superapp'],
  },
  dapp_mint_reservoir: {
    killSwitchName: 'kill_dapp_mint_reservoir',
    modes: ['superapp'],
  },
  dapp_mint_native: {
    killSwitchName: 'kill_dapp_mint_native',
    modes: ['superapp'],
  },
  wallet_tab_long_press: {
    killSwitchName: 'kill_wallet_tab_long_press',
    modes: ['superapp'],
  },
  send_spam_token_warning: {
    killSwitchName: 'kill_send_spam_token_warning',
    modes: ['superapp', 'payments'],
  },
  dapp_assets_filters_revamp: {
    killSwitchName: 'kill_dapp_assets_filters_revamp',
    modes: ['superapp'],
  },
  add_web3_starter_kit_fund_action: {
    experiments: {
      ios: experiments.may_2024_add_fund_web3_starter_kit_action,
      android: experiments.may_2024_add_fund_web3_starter_kit_action,
    },
    killSwitchName: 'kill_add_fund_web3_starter_kit_action',
    modes: ['superapp'],
  },
  ocs_coffee_days: {
    killSwitchName: 'kill_ocs_coffee_days',
    modes: ['superapp'],
  },
  explore_pages: {
    killSwitchName: 'kill_explore_pages',
    modes: ['superapp'],
  },
  cross_chain_swap: {
    killSwitchName: 'kill_cross_chain_swap',
    experiments: {
      ios: experiments.may_2024_cross_chain_swap,
      android: experiments.may_2024_cross_chain_swap,
    },
    modes: ['superapp'],
  },
  cross_chain_swap_bridge_redirect: {
    killSwitchName: 'kill_cross_chain_swap_bridge_redirect',
    modes: ['superapp'],
  },
  tron_usdt_receive: {
    killSwitchName: 'kill_tron_usdt_receive',
    experiments: {
      ios: experiments.may_2024_tron_usdt_receive,
      android: experiments.may_2024_tron_usdt_receive,
    },
    modes: ['superapp', 'payments'],
  },
  social_feed_pano: {
    killSwitchName: 'kill_social_feed',
    modes: ['superapp'],
    experiments: {
      web: experiments.may_2024_social_page_web,
    },
  },
  social_native_mint: {
    killSwitchName: 'kill_social_native_mint',
    modes: ['superapp'],
    experiments: {
      web: experiments.may_2024_social_native_mint,
    },
  },
  social_mint_explore: {
    killSwitchName: 'kill_social_mint_explore',
    modes: ['superapp'],
  },
  social_create_mint: {
    experiments: {
      ios: experiments.sep_2024_social_feed_mint,
      android: experiments.sep_2024_social_feed_mint,
    },
    modes: ['superapp'],
  },
  explore_on_home_redesign: {
    killSwitchName: 'kill_explore_on_home_redesign',
    modes: ['superapp'],
    experiments: {
      ios: experiments.may_2024_explore_on_home_redesign,
      android: experiments.may_2024_explore_on_home_redesign,
    },
  },
  watchlist_notif_optin: {
    killSwitchName: 'kill_watchlist_notif_opt_in',
    modes: ['superapp'],
    experiments: {
      ios: experiments.may_2024_watchlist_notif_opt_in,
      android: experiments.may_2024_watchlist_notif_opt_in,
    },
  },
  add_to_watchlist_tray: {
    killSwitchName: 'kill_add_to_watchlist_tray',
    modes: ['superapp'],
    experiments: {
      ios: experiments.jun_2024_add_to_watchlist_tray,
      android: experiments.jun_2024_add_to_watchlist_tray,
    },
  },
  wallet_tap_to_pay: {
    killSwitchName: 'enable_nfc_send',
    modes: ['superapp', 'payments'],
  },
  wallet_tap_to_pay_receiver: {
    killSwitchName: 'enable_tap_to_pay_receiver_flow',
    modes: ['superapp', 'payments'],
  },
  wallet_tap_to_pay_sender: {
    killSwitchName: 'enable_tap_to_pay_sender_flow',
    modes: ['superapp', 'payments'],
  },
  wallet_tap_to_pay_v2: {
    killSwitchName: 'enable_wallet_tap_to_pay_v2',
    modes: ['superapp', 'payments'],
  },
  wallet_tap_to_pay_v2_merchant: {
    killSwitchName: 'enable_wallet_tap_to_pay_v2_merchant',
    modes: ['superapp', 'payments'],
  },
  wallet_tap_to_pay_v2_customer: {
    killSwitchName: 'enable_wallet_tap_to_pay_v2_customer',
    modes: ['superapp', 'payments'],
  },
  preference_all_smart_wallet: {
    killSwitchName: 'enable_preference_all_smart_wallet',
    modes: [],
  },
  // scw chains
  scw_chain_arbitrum: {
    killSwitchName: 'kill_scw_chain_arbitrum',
    modes: [],
  },
  scw_chain_avalanche: {
    killSwitchName: 'kill_scw_chain_avalanche',
    modes: [],
  },
  scw_chain_base: {
    killSwitchName: 'kill_scw_chain_base',
    modes: [],
  },
  scw_chain_bsc: {
    killSwitchName: 'kill_scw_chain_bsc',
    modes: [],
  },
  scw_chain_mainnet: {
    killSwitchName: 'kill_scw_chain_mainnet',
    modes: [],
  },
  scw_chain_optimism: {
    killSwitchName: 'kill_scw_chain_optimism',
    modes: [],
  },
  scw_chain_polygon: {
    killSwitchName: 'kill_scw_chain_polygon',
    modes: [],
  },
  scw_chain_zora: {
    killSwitchName: 'kill_scw_chain_zora',
    modes: [],
  },
  warm_welcome: {
    killSwitchName: 'kill_warm_welcome',
    experiments: {
      ios: experiments.may_2024_warm_welcome,
      android: experiments.may_2024_warm_welcome,
    },
    modes: ['superapp'],
  },
  scw_onboarding: {
    killSwitchName: 'kill_scw_onboarding',
    modes: [],
  },
  scw_onboarding_close: {
    experiments: {
      web: experiments.jul_2024_scw_onboarding_close,
    },
    modes: [],
  },
  swap_mode_best: {
    experiments: {
      ios: experiments.jun_2024_swap_mode_best,
      android: experiments.jun_2024_swap_mode_best,
      extension: experiments.jun_2024_swap_mode_best,
      web: experiments.jun_2024_swap_mode_best,
    },
    modes: ['superapp'],
  },
  scw_magic_link: {
    killSwitchName: 'kill_scw_magic_link',
    modes: [],
    experiments: {
      web: experiments.jun_2024_scw_magic_link,
    },
  },
  scw_session_keys: {
    killSwitchName: 'enable_scw_session_keys',
    modes: [],
    experiments: {
      web: experiments.sep_2024_scw_session_keys,
    },
  },
  scw_extension_option: {
    killSwitchName: 'kill_scw_extension_option',
    modes: [],
  },
  scw_walletlink_option: {
    killSwitchName: 'kill_scw_walletlink_option',
    modes: [],
  },
  scw_recovery: {
    killSwitchName: 'kill_scw_recovery',
    experiments: {
      web: experiments.jun_2024_scw_recovery,
    },
    modes: [],
  },
  scw_recovery_promotional_banner: {
    killSwitchName: 'kill_scw_recovery_promotional_banner',
    modes: [],
  },
  scw_auto_switch_payment_method: {
    killSwitchName: 'kill_scw_auto_switch_payment_method',
    modes: [],
  },
  scw_replays: {
    killSwitchName: 'kill_scw_replays',
    modes: [],
  },
  scw_retries: {
    killSwitchName: 'kill_scw_retries',
    modes: [],
  },
  scw_skip_transaction_confirmation: {
    killSwitchName: 'kill_scw_skip_transaction_confirmation',
    modes: [],
  },
  import_mnemonic_warning: {
    killSwitchName: 'kill_import_mnemonic_warning',
    experiments: {
      ios: experiments.jun_2024_import_mnemonic_warning_rn,
      android: experiments.jun_2024_import_mnemonic_warning_rn,
      extension: experiments.jun_2024_import_mnemonic_warning_extension,
    },
    modes: ['superapp', 'payments'],
  },
  assets_percentage_change: {
    experiments: {
      ios: experiments.jun_2024_assets_percentage_change,
      android: experiments.jun_2024_assets_percentage_change,
    },
    killSwitchName: 'kill_assets_percentage_change',
    modes: ['superapp'],
  },
  adp_actions_new_layout: {
    killSwitchName: 'kill_adp_actions_new_layout',
    modes: ['superapp', 'payments'],
  },
  mint_by_credit_card: {
    killSwitchName: 'kill_mint_by_credit_card',
    modes: ['superapp'],
  },
  payments_mode_announcements: {
    killSwitchName: 'kill_payments_mode_announcements',
    modes: ['payments'],
  },
  app_mode_notification_preferences: {
    killSwitchName: 'kill_app_mode_notification_preferences',
    experiments: {
      ios: experiments.jul_2024_app_mode_notification_preferences,
      android: experiments.jul_2024_app_mode_notification_preferences,
    },
    modes: ['payments', 'superapp'],
  },
  gallery_page: {
    killSwitchName: 'kill_gallery_page',
    modes: ['superapp'],
  },
  scw_webauthn_p256_authentication: {
    killSwitchName: 'kill_scw_webauthn_p256_authentication',
    modes: [],
    experiments: {
      web: experiments.aug_2024_scw_webauthn_p256_authentication,
    },
  },
  mev_protection: {
    killSwitchName: 'kill_mev_protection',
    modes: ['superapp'],
    experiments: {
      ios: experiments.jun_2024_mev_experiment,
      android: experiments.jun_2024_mev_experiment,
      extension: experiments.jun_2024_mev_experiment,
    },
  },
  wallet_card: {
    killSwitchName: 'enable_wallet_card',
    modes: ['superapp', 'payments'],
  },
  explore_search_refresh: {
    killSwitchName: 'kill_explore_search_refresh',
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.aug_2024_explore_refresh,
      android: experiments.aug_2024_explore_refresh,
    },
  },
  ripio_payment_method_screen_upsell: {
    killSwitchName: 'kill_ripio_payment_method_screen_upsell',
    modes: ['superapp', 'payments'],
  },
  deeplinks_v2_delegation: {
    killSwitchName: 'enable_deeplinks_v2_delegation',
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.aug_2024_deeplink_v2,
      android: experiments.aug_2024_deeplink_v2,
    },
  },
  sprig_web: {
    killSwitchName: 'kill_sprig',
    modes: ['superapp', 'payments'],
  },
  send_via_link_pano_receive: {
    killSwitchName: 'kill_pano_svl_receive',
    modes: ['superapp', 'payments'],
  },
  tx_confirmation_rearchitecture_swap: {
    killSwitchName: 'kill_tx_confirmation_rearchitecture_swap',
    experiments: {
      ios: experiments.jul_2024_tx_confirmation_rearchitecture_swap,
      android: experiments.jul_2024_tx_confirmation_rearchitecture_swap,
    },
    modes: ['superapp'],
  },
  pano_swap_disconnected_state: {
    killSwitchName: 'kill_pano_swap_disconnected_state',
    experiments: {
      web: experiments.jul_2024_pano_swap_disconnected_state,
    },
    modes: ['superapp'],
  },
  tx_confirmation_rearchitecture_send: {
    killSwitchName: 'kill_tx_confirmation_rearchitecture_send',
    experiments: {
      ios: experiments.jul_2024_wallet_rn_tx_confirmation_rearchitecture_send,
      android: experiments.jul_2024_wallet_rn_tx_confirmation_rearchitecture_send,
    },
    modes: ['superapp', 'payments'],
  },
  virtual_account_number: {
    killSwitchName: 'kill_usd_van_accounts',
    experiments: {
      ios: experiments.aug_2024_van_accounts,
      android: experiments.aug_2024_van_accounts,
    },
    modes: ['superapp', 'payments'],
  },
  virtual_account_number_microdeposits: {
    killSwitchName: 'kill_usd_van_accounts_microdeposits',
    modes: ['superapp', 'payments'],
  },
  virtual_account_number_us: {
    killSwitchName: 'kill_usd_van_accounts_us',
    experiments: {
      ios: experiments.sep_2024_van_accounts_us,
      android: experiments.sep_2024_van_accounts_us,
    },
    modes: ['superapp', 'payments'],
  },
  virtual_account_number_global: {
    killSwitchName: 'kill_usd_van_accounts_global',
    experiments: {
      ios: experiments.sep_2024_van_accounts_global,
      android: experiments.sep_2024_van_accounts_global,
    },
    modes: ['superapp', 'payments'],
  },
  walletlink_modal_v2: {
    killSwitchName: 'enable_walletlink_modal_v2',
    experiments: {
      ios: experiments.jul_2024_walletlink_modal_v2,
      android: experiments.jul_2024_walletlink_modal_v2,
    },
    modes: ['superapp'],
  },
  aleph_crecimiento_popup_event: {
    killSwitchName: 'kill_aleph_crecimiento_popup_event',
    modes: ['payments'],
  },
  tx_confirmation_rearchitecture_bridge: {
    killSwitchName: 'kill_tx_confirmation_rearchitecture_bridge',
    experiments: {
      ios: experiments.jul_2024_wallet_rn_tx_confirmation_rearchitecture_bridge,
      android: experiments.jul_2024_wallet_rn_tx_confirmation_rearchitecture_bridge,
    },
    modes: ['superapp'],
  },
  tx_confirmation_rearchitecture_web3: {
    killSwitchName: 'kill_tx_confirmation_rearchitecture_web3',
    experiments: {
      ios: experiments.sep_2024_wallet_rn_tx_confirmation_rearchitecture_web3,
      android: experiments.sep_2024_wallet_rn_tx_confirmation_rearchitecture_web3,
    },
    modes: ['superapp'],
  },
  swap_coinbase_fee: {
    killSwitchName: 'kill_swap_fee',
    modes: ['superapp'],
  },
  wallets_rn_filter_network: {
    experiments: {
      ios: experiments.aug_2024_wallets_rn_filter_network,
      android: experiments.aug_2024_wallets_rn_filter_network,
    },
    killSwitchName: 'kill_wallets_rn_filter_network',
    modes: ['superapp'],
  },
  transactions_pagination: {
    experiments: {
      android: experiments.aug_2024_transactions_pagination,
      ios: experiments.aug_2024_transactions_pagination,
    },
    modes: ['superapp'],
  },
  mint_feed: {
    killSwitchName: 'kill_mint_feed',
    modes: ['superapp'],
  },
  social_trending_swap: {
    killSwitchName: 'kill_social_trending_swap',
    modes: ['superapp'],
  },
  composer_actions: {
    killSwitchName: 'kill_composer_actions',
    modes: ['superapp'],
  },
  social_trending_mint: {
    killSwitchName: 'kill_social_trending_mint',
    modes: ['superapp'],
  },
  introducing_smart_wallet_mint: {
    killSwitchName: 'kill_introducing_smart_wallet_mint',
    modes: [],
    experiments: {
      web: experiments.aug_2024_introducing_smart_wallet_mint,
    },
  },
  social_feed: {
    experiments: {
      android: experiments.sep_2024_wallet_social_feed,
      ios: experiments.sep_2024_wallet_social_feed,
    },
    killSwitchName: 'kill_social_feed',
    modes: ['superapp'],
  },
  deeplinks_v2_branch: {
    killSwitchName: 'enable_deeplinks_v2_branch',
    modes: ['superapp', 'payments'],
  },
  hide_unhide_assets: {
    killSwitchName: 'kill_wallet_hide_unhide_assets',
    modes: ['superapp', 'payments'],
  },
  cross_chain_swap_slippage: {
    killSwitchName: 'kill_cross_chain_swap_slippage',
    modes: ['superapp'],
  },
  signout_v2: {
    killSwitchName: 'kill_signout_flow_v2',
    modes: ['superapp', 'payments'],
    experiments: {
      ios: experiments.sep_2024_signout_flow_v2,
      android: experiments.sep_2024_signout_flow_v2,
    },
  },
  linkdrop_v3_12: {
    killSwitchName: 'kill_linkdrop_v3_12',
    modes: ['superapp', 'payments'],
  },
  defi_tab_all_networks_rn: {
    killSwitchName: 'kill_defi_tab_all_networks_rn',
    experiments: {
      ios: experiments.sep_2024_defi_tab_all_networks_rn,
      android: experiments.sep_2024_defi_tab_all_networks_rn,
    },
    modes: ['superapp'],
  },
  trading_activity_adp: {
    killSwitchName: 'kill_trading_activity_adp',
    experiments: {
      web: experiments.sept_2024_trading_activity_adp,
    },
    modes: ['superapp'],
  },
  deposit_ui: {
    killSwitchName: 'kill_deposit_ui',
    modes: ['payments'],
  },
  real_time_swaps: {
    experiments: {
      ios: experiments.aug_2024_real_time_swaps,
      android: experiments.aug_2024_real_time_swaps,
    },
    killSwitchName: 'kill_real_time_swap_sends',
    modes: ['superapp'],
  },
  real_time_swaps_pay_with_usdc: {
    experiments: {},
    killSwitchName: 'kill_real_time_swap_sends_pay_with_usdc',
    modes: ['superapp'],
  },
} as const;

/* eslint-enable camelcase */
