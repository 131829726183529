/* eslint-disable camelcase */

// Helper function to enforce the type and capture the specific keys.
// - The main reason to type killSwitchesByPlatformConfig is to restrict the
//   value to type KillSwitchByPlatformConfigValue.
// - Without this, the type of the key would be string. Now it is the union
//   of all keys, resulting in KillSwitchByPlatformConfigKey.
function createKillSwitchesByPlatformConfig<
  T extends Record<string, KillSwitchByPlatformConfigValue>,
>(config: T): T {
  return config;
}

export type KillSwitchByPlatformConfigKey = keyof typeof killSwitchesByPlatformConfig;
export type KillSwitchByPlatformConfigValue =
  | {
      rn: boolean;
      android?: never;
      ios?: never;
      extension: boolean;
      web: boolean;
    }
  | {
      rn?: never;
      android: boolean;
      ios: boolean;
      extension: boolean;
      web: boolean;
    };

// This utility type extracts the value types from a type's properties
type ValueOf<T> = T[keyof T];

// KillSwitchPlatform is all the second-level keys of killSwitchesByPlatformConfig
// (e.g., 'android', 'ios', 'web', 'extension')
export type KillSwitchPlatform = ValueOf<{
  [K in keyof typeof killSwitchesByPlatformConfig]: keyof KillSwitchByPlatformConfigValue;
}>;

export type KillSwitchByPlatform = `${KillSwitchByPlatformConfigKey}_${KillSwitchPlatform}`;

/**
 * Kill switches must be created in config service and named accordingly:
 * - kill_feature_platform
 *
 * It is ideal and strongly encouraged to use one for each platform:
 * - ios
 * - android
 * - web
 * - extension
 */
export const killSwitchesByPlatformConfig = createKillSwitchesByPlatformConfig({
  kill_switch_to_scw_v2: {
    android: false,
    ios: false,
    web: false,
    extension: false,
  },
  kill_switch_to_ext_v2: {
    android: false,
    ios: false,
    web: false,
    extension: false,
  },
  kill_human_readable_transactions: {
    android: true,
    ios: true,
    web: true,
    extension: false,
  },
  kill_user_interests_quiz: {
    android: false,
    ios: false,
    extension: true,
    web: true,
  },
  kill_asset_details_watch_button: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_txhistory_service_network_base_sepolia: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_holesky: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_sepolia: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_optimism_sepolia: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_ftm_testnet: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_polygon_testnet: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_arbitrum_sepolia: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_avax_fuji: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_txhistory_service_network_bnb_testnet: {
    rn: true,
    web: true,
    extension: true,
  },
  kill_nft_nux_v2: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_nft_ticketing: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_global_trays_on_qr_code_scan: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_top_up_for_all_supported_assets: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_handle_wallet_not_found_error: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_dynamic_send_via_link: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_send_via_link_config_per_asset: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_send_via_link_qr_code: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_send_flow_pfp: {
    ios: false,
    android: false,
    web: false,
    extension: true,
  },
  kill_send_confirmation_gasless_upsell: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_send_success_gasless_upsell: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_address_book_send: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_send_predictive_search: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_search_receive_asset: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_edit_qr_code: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_standardized_qr_codes: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_receive_wallet_group_infos: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_address_observability: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_send_blockchain_xlm: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_send_blockchain_xrp: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_send_blockchain_bch: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_xlm: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_xrp: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_bch: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_private_key_import: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_profile: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_sprig: {
    rn: false,
    web: false,
    extension: true,
  },
  kill_defi_tab: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_cross_chain_swap: {
    extension: false,
    web: false,
    ios: false,
    android: false,
  },
  kill_watchlist: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_attestations: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_switch_profiles_balance_parity: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_asset_management: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_cloud_backup_trays: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_cross_platform_hidden_assets: {
    rn: false,
    extension: false,
    web: false,
  },
  enable_new_settings_multi_account: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_multiaccount_announcement: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_multi_wallet_assets_long_press: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_mismatched_address_force_signout: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_cache_retail_accounts: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_tabbar_visibility_toggle: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_search_result_avatar: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_coinbase_connect_wrss_sync: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_blocked_dns_screen: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_txhistory_service: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_bsc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_ftm: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_gnosis: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_polygon: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_optimism: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_arbitrum: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_avalanche_c: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_etc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_sol: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_base_v2: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_zetachain: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_network_zora: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_tx_history_gasless_upsell: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  enable_human_readable_transactions_v2: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_bsc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_ftm: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_gnosis: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_polygon: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_optimism: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_arbitrum: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_avalanche_c: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_etc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_sol_v3: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_base_v2: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_zetachain: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_zora: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_base_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_holesky: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_optimism_sepolia: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_sepolia: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_bnb_testnet: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_avax_fuji: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_ftm_testnet: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_arbitrum_sepolia: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_txhistory_service_v3_network_polygon_testnet: {
    rn: true,
    extension: false,
    web: false,
  },
  kill_address_history_transactions: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_solana_websocket_nudges: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_unsandboxed_nfts: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_switch_nft_portfolio_tab: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_nft_offers_tab: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_nft_history_tab: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_aggregate_bids: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_aggregate_listings: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_gnosis_poaps: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_gnosis_poaps_send: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_recent_collectibles: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_accept_bids: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_nft_native_buy: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_nft_marketplace_links: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_polygon_nfts: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_use_floor_price: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_nft_collection_sort_by_chain: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_nft_offers_alert_tray: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_nft_round_corners: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_switch_nft_send: {
    android: false,
    ios: true,
    extension: false,
    web: true,
  },
  kill_switch_view_solana_nft: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_switch_paginated_collections: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_solana_nft_refresh: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_optimism_nfts: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_optimism_nfts_send: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_switch_reservoir_notification: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_base_nfts: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_base_nfts_send: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_wallet_music_nfts: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_backend_based_nft_chain_config: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_nft_pfp: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  // Kill switch to enable showing a remote image as a pfp.
  // This can be removed once we want to enable this fully in dapp.
  // TODO (WALL-37325): On dapp there is a bug where on sirtestalot the 3rd address's remote image is
  // not cached and refetches every time it's shown.  This should be fixed before
  // enabling the kill switch.
  enable_wallet_pfp: {
    ios: true,
    android: true,
    extension: true,
    web: false,
  },
  kill_nft_grid_view: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_collections_grid_view: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_switch_pending_collectible: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_nft_grid_increased_page_size: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_nft_tab_sorting: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_mesh_connect: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_nft_aggregated_oe721s: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_retail_suggested_destination: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_dapp_browser_ios: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_dapp_browser_android: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_dapp_browser_deeplinks_ios: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_dapp_browser_deeplinks_android: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_dapp_lists: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_browser_tab_screenshot: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_browser_cache_clear: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_browser_loading_status_animation: {
    ios: true,
    android: true,
    web: false,
    extension: false,
  },
  kill_browser_nav_redesign: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_browser_mute_media_injected_js: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_browser_pull_to_refresh: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_dapp_browser_category_fetch: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_swap_ethereum: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_polygon: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_avax: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_solana: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_bsc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_base: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_optimism: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_free_usdc_swap: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_private_pool: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_swap_coinbase_fee: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dex_deposit_gas: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_standardize_errors_dex: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_gasless_swap_eth: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_gasless_swap_polygon: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_backend_driven_gasless_eligibility: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_adjustable_slippage: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_fiat_ui_copies: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_bridge: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_real_time_send_gas_fee: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_recipient_suggestions_my_addresses_tab: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_recipient_suggestions_recents_tab: {
    rn: true,
    extension: true,
    web: true,
  },
  kill_warning_on_send_confirmation: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_dynamic_send_via_link_adp_banner: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_solana_transaction_priority_fee: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_send_blockchain_all: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_blockchain_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_blockchain_sol: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_blockchain_btc: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_send_blockchain_doge: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_send_blockchain_ltc: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_send_network_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_etc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_bsc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_ftm: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_gnosis: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_polygon: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_optimism: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_arbitrum: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_avalanche_c: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_base_v2: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_zetachain: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_zora: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_base_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_holesky: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_optimism_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_ftm_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_polygon_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_arbitrum_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_avax_fuji: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_send_network_bnb_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_all: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_sol: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_btc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_doge: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_blockchain_ltc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_etc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_bsc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_ftm: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_gnosis: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_polygon: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_optimism: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_arbitrum: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_avalanche_c: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_base_v2: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_zetachain: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_zora: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_base_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_holesky: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_optimism_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_ftm_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_polygon_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_arbitrum_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_avax_fuji: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_dapp_tx_network_bnb_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_repair_db_with_wallets_if_needed: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_mnemonic_escape_hatch: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_repair_mnemonic_accounts: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_data_migration_runner: {
    rn: false,
    extension: false,
    web: false,
  },
  enable_mnemonic_account_consistency_check: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_show_notifications_permissions_post_onboarding: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_wallet_settings_hub_notif_optin: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_load_existing_browser_tab: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_chain_network_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_polygon: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_bsc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_ftm: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_arbitrum: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_gnosis: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_avalanche_c: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_optimism: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_etc: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_base_v2: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_zetachain: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_zora: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_base_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_holesky: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_optimism_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_ftm_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_polygon_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_arbitrum_sepolia: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_avax_fuji: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_chain_network_bnb_testnet: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_cb_one_swap_fees: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_cbpay: {
    rn: false,
    extension: false,
    web: true,
  },
  enable_cbpay_sdk: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_apollo_new_cbpay_params: {
    rn: false,
    web: false,
    extension: false,
  },
  enable_add_card_header: {
    rn: false,
    web: false,
    extension: false,
  },
  kill_explore_multichain: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_explore_nft: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_explore_search: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_explore_tab: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_explore_quests: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_explore_mint_streaks: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_explore_dynamic_module: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_explore_nux_screen: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_explore_minting_service_collections: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_explore_mint_dot_fun_collections: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  enable_mint_streak_collection_gtm: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_last_used_provider: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_cbpay_provider_interstitial: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_explore_cb_nft_collections: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_explore_trending_collections_tab: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_gas_tracker: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_streaks_v2: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_stake: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_staking_top_up: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_stake_multi_provider: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_stake_matic: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_delegator_staking: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_earn_balance: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_switch_transaction_preview: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_tx_scanner_simulation: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_switch_token_approvals: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_transaction_preview_solana: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_message_scan_v1: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_message_scan_v2: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_message_scan_seaport_warning: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_eoa_approval_fraud_warning: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_dapp_tx_confirmation_scam_warning: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_utxo_tx_cancel: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_eth_tx_cancel: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_utxo_tx_speed_up: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_eth_tx_speed_up: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_recent_recipients_tab_v3: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_human_readable_transactions_ui: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_txhistory_nudge_blockchain_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_txhistory_nudge_blockchain_sol: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_balance_nudge_blockchain_eth: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_balance_nudge_blockchain_sol: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_das_api_blockchain_sol: {
    rn: false,
    extension: false,
    web: false,
  },
  enable_balance_fetching_throttle: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_onramp_on_send: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_onramp_on_receive: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_minting_service_collections: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_mint_dot_fun_collections: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_cb_nft_collections: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_trending_collections_tab: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_umo: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_guest_checkout: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_unified_login: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_buy_add_payment_method: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_quick_buy_eth_modal: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_gasless_purchase_modal: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_top_up_for_web3_transactions: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_top_up_v2: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_cbke: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_pending_asset: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_usdc_rewards: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_usdc_rewards_summary: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_bridge_base_upsell: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_force_skip_tracking_onramp_experiment: {
    rn: false,
    web: false,
    extension: false,
  },
  enable_nux_nft_incentive_entry_modal: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_browser_nux_tray: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_base_browser_nux_tray: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_assets_tab_nux_cards: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_fund_and_swap_on_explore: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_instant_funding_flow: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_web3_starter_kit_on_asset_list: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_initialize_local_currency: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_currency_selector: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_swap_assets: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_native_buy: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_scw_onramp_native_buy: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_ocb_deeplink: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_cb_native_buy: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_unsupported_payment_method_tray: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_mgx_unconnected_state: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_onramp_confirmation_screen: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_nft_incentive_with_usdc: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_authenticated_webview_wsst_flow: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_blocklist_integration: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_account_activity: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_product_announcement: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_promotion: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_price_alert: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_price_alert_btc: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_price_alert_eth: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_price_alert_owned: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_price_alert_trending: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_network_fee_alert_ethereum: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_feature_tip: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_trending_dapp: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_wallet_activity: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_settings_nft_offers_alert: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notifications_hub_v2: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_notification_settings_wallet_messaging: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_notification_hub_library: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_solana_web3: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_quests: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_referral_hub: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_native_sdk: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_check_auth_state_and_accounts_sign_out: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_native_asset_max_button_send: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_bridge_chain_1: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_native_asset_max_button_bridge_chain_137: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_native_asset_max_button_stake_chain_1: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_1: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_137: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_56: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_43114: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_10: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_101: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_8453: {
    android: false,
    ios: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_42161: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_17000: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_swap_chain_7777777: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_native_asset_max_button_bridge_chain_8453: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_native_asset_max_button_bridge_chain_10: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_native_asset_max_button_bridge_chain_42161: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_native_asset_max_button_buy: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_revoke_approvals: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_subdomain_claiming: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  enable_did_non_primary_wallets: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_export_private_key: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_create_new_mnemonic_account: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_import_new_mnemonic_account: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_error_boundary_escape_hatch: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_messaging: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_payment_request: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_attachments: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_attachment_sends: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_mute_conversation: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_subscribe: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_suggestions: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_report_wallet_address: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_address_scoring: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_chatbot_referral_processing: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_fsto: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_tab_counts: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_request_management: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_subscription_discovery: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_inbox_subscription_discovery: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_xmtp_rn_conversation_status: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_xmtp_rn_sdk: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_messaging_xmtp_rn_sdk_listener_v2: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_messaging_open_frames: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_missing_mnemonic_escape_hatch: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_utxo_blockheight_syncing: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_utxo_pending_tx_tray: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_pending_utxo_aware_balances: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  kill_utxo_transaction_blockheight_syncing: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_utxo_script_from_transaction: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_utxo_replace_by_fee: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_widgets_announcement: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_web3_starter_kit: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_spam_score: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_asset_metadata_v4_migration: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_switch_layout_animation: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_wallet_backfilling: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  enable_replace_existing_wallets: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_utxo_announcement_banner: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_payments_risk_threatmetrix_config: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_solarisbank_skip: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_switch_sponsored_dapp: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_contentful_announcements: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_switch_onchain_summer_upcoming_drops: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_switch_onchain_summer_minting: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_explore_upcoming_drop_notification_link: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_defi_education: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_recovery_mode: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_testnet_mode_enabled: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_account_backup_reminders: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_instant_onboarding_full_screen_reminder: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_translate_branch_into_static_deeplink: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  enable_wallets_atom_debounce: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_onramp_provider_tray: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_gift_links2: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_dynamic_send_via_link_send_input_option: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_onboarding_accounts_guardrail: {
    android: false,
    ios: false,
    web: false,
    extension: false,
  },
  kill_landing_accounts_guardrail: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_payments_mode: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_onboard_default_payments_mode: {
    ios: false,
    android: false,
    extension: true,
    web: true,
  },
  kill_payments_mode_browser: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_referral: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_address_scanner: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_instant_onboarding_did_claim_takeover: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_onboarding_buy_bonus: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_upsell_announcement_banner: {
    ios: false,
    android: false,
    extension: true,
    web: true,
  },
  kill_rp_secure_indicator: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_guided_import_paths: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_skip_restore_wallet: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_wallet_group_balances: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_extension_promotion_announcement: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_fe_tracing: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_offline_notification: {
    rn: false,
    web: true,
    extension: true,
  },
  kill_switch_explore_partner_drops: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_cashout: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_cashout_international_mvp: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_cashout_international_payments_mode: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_cashout_retail_fiat_account_expansion: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_cashout_asset_details: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_sponsored_cashout: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_delete_invalid_accounts_recovery: {
    ios: false,
    android: false,
    extension: false,
    web: true,
  },
  kill_account_limit_increase: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_group_limit_increase: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_test_kill_switch: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_global_search: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_wallet_global_search_using_nlp: {
    ios: false,
    android: false,
    web: false,
    extension: true,
  },
  kill_wallet_global_search_recent_searches: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_wallet_global_search_settings: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_wallet_global_search_typeahead_search: {
    ios: false,
    android: false,
    web: false,
    extension: true,
  },
  kill_wallet_global_search_using_wac: {
    ios: false,
    android: false,
    web: false,
    extension: true,
  },
  kill_receive: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_send_nft: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_wallet_app_architecture: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_wallet_app_architecture_messaging: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_wallet_locale_setting: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_walletlink_gns_migration: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_hide_profile_assets: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_user_events_action_service: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_txn_complete_notif_optin: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_coin_tracker_promo: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_crypto_tax_calculator_promo: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_provider_coinbase_wallet: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_provider_phantom: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_provider_walletconnect: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_provider_mipd: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_native_storage_logging: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_auth_gate_root_stack: {
    android: false,
    ios: false,
    extension: false,
    web: true,
  },
  enable_global_tray_provider: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_default_virtual_tray: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_exchange_rate_override: {
    ios: false,
    android: false,
    extension: true,
    web: false,
  },
  kill_test_switch_wallet: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_ledger_onboarding: {
    web: true,
    extension: true,
    rn: true,
  },
  kill_announcement_banner: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_switch_aggregate_bids_ext: {
    web: true,
    extension: true,
    rn: true,
  },
  kill_solana_nft_refresh_ext: {
    web: true,
    extension: true,
    rn: true,
  },
  kill_adjustable_miner_fee: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_cb_generic_sign_and_submit: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_delete_accounts_with_no_mnemonic: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_explore_cta_tray: {
    extension: true,
    web: true,
    rn: true,
  },
  kill_community_tab: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_switch_transaction_preview_native_send: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_notifications_hub: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_solana_web3_provider: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_testnet_faucet: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_testnet_faucet_bridge: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_testnet_tab: {
    extension: false,
    web: true,
    rn: true,
  },
  utxo_cancel_bip_125: {
    extension: false,
    web: false,
    rn: false,
  },
  kill_permissions_prompt: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_dapp_linkout_v2: {
    extension: false,
    web: true,
    rn: true,
  },
  kill_display_cbpay_buy_button_navbar: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_native_asset_max_button_swap_chain_250: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_native_asset_max_button_swap_chain_11155111: {
    web: false,
    extension: true,
    rn: true,
  },
  enable_eth_denver_2024: {
    web: false,
    extension: false,
    rn: false,
  },
  kill_send_network_base: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_magic_link: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_magic_spend: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_magic_spend_2fa: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_mainnet_chains: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_display_cbpay_buy_button_navbar: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_swap: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_send_blockchain_all: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_add_wallet: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_scw_sign_in_with_ethereum_auth: {
    web: true,
    extension: true,
    rn: true,
  },
  enable_scw_onboarding_fund_wallet: {
    web: false,
    extension: false,
    rn: false,
  },
  enable_did_non_primary: {
    web: false,
    extension: false,
    rn: false,
  },
  kill_wallet: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_dapps_page: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_global_search: {
    web: false,
    extension: true,
    rn: true,
  },
  kill_referral_claim_cta: {
    web: true,
    extension: true,
    ios: true,
    android: true,
  },
  kill_swap_share: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_swap_asset_ribbon: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_swap_share_card: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_mnemonic_salt_recovery: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_walletlink_onboarding: {
    rn: true,
    web: true,
    extension: false,
  },
  kill_wallet_redesign_payments_tab: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_redesign_browser_trending_dapps: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_redesign_top_level_messaging_tab: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_wallet_redesign_miami_vice_theme_colors: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_redesign_miami_vice: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_redesign_fsto: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_wallet_redesign_miami_button: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_redesign_transactions_tab: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_view_profile: {
    android: false,
    ios: false,
    web: true,
    extension: true,
  },
  kill_wallet_redesign_avatars: {
    android: false,
    ios: false,
    web: false,
    extension: true,
  },
  kill_wallet_hide_no_quote_with_low_balance: {
    android: false,
    ios: false,
    web: false,
    extension: false,
  },
  kill_dapp_assets_crypto_tab: {
    rn: true,
    extension: true,
    web: false,
  },
  kill_dapp_assets_nft_tab: {
    rn: true,
    extension: true,
    web: true,
  },
  kill_dapp_assets_defi_tab: {
    rn: true,
    extension: true,
    web: false,
  },
  kill_dapp_assets_transactions_tab: {
    rn: true,
    extension: true,
    web: false,
  },
  kill_dapp_assets_creations_tab: {
    rn: true,
    extension: true,
    web: true,
  },
  kill_scw_recovery: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_scw_recovery_promotional_banner: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_scw_auto_switch_payment_method: {
    rn: false,
    extension: false,
    web: false,
  },
  kill_drops_page: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_mint_feed_page: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_social_native_mint: {
    ios: true,
    android: true,
    web: false,
    extension: true,
  },
  kill_social_mint_explore: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_social_page: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_scw_buy_button: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_filter_poisonous_transactions: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_dapp_datadog_synthetics_page: {
    web: false,
    extension: true,
    rn: true,
  },
  enable_address_poisoning_check: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_alternate_address_poisoning_check: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_connect_to_coinbase: { ios: false, android: false, extension: false, web: false },
  kill_send_spam_token_warning: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_dapp_mint_reservoir: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_dapp_mint_native: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_wallet_tab_long_press: {
    ios: false,
    android: false,
    extension: true,
    web: true,
  },
  kill_claim_links_erc20_assets: {
    ios: true,
    android: true,
    extension: false,
    web: false,
  },
  kill_dapp_assets_filters_revamp: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  enable_linkdrop_send_via_link_transfer_limits: {
    ios: false,
    android: false,
    extension: false,
    web: false,
  },
  kill_add_fund_web3_starter_kit_action: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_ocs_coffee_days: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_tron_usdt_receive: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_explore_pages: {
    ios: false,
    android: false,
    web: true,
    extension: false,
  },
  kill_social_feed: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_explore_on_home_redesign: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_watchlist_notif_opt_in: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_add_to_watchlist_tray: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_nfc_send: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_preference_all_smart_wallet: {
    ios: false,
    android: false,
    web: true,
    extension: false,
  },
  // scw chains
  kill_scw_chain_arbitrum: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_avalanche: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_base: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_bsc: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_mainnet: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_optimism: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_polygon: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_chain_zora: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_app_paymasters: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_cloud_paymasters: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_sepolia_paymaster: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_warm_welcome: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_scw_onboarding: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_warn_sends_to_known_contracts: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_tap_to_pay_receiver_flow: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_tap_to_pay_sender_flow: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_wallet_tap_to_pay_v2: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_wallet_tap_to_pay_v2_merchant: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_wallet_tap_to_pay_v2_customer: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_extension_option: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_walletlink_option: { ios: false, android: false, web: false, extension: false },
  kill_import_mnemonic_warning: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_adp_actions_new_layout: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_mint_by_credit_card: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_assets_percentage_change: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_scw_replays: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_retries: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_webauthn_p256_registration: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_webauthn_p256_authentication: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_scw_skip_transaction_confirmation: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_payments_mode_announcements: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_gallery_page: {
    ios: false,
    android: false,
    web: true,
    extension: false,
  },
  kill_mev_protection: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_app_mode_notification_preferences: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_simple_mode_notifications: {
    rn: false,
    web: true,
    extension: true,
  },
  enable_wallet_card: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_explore_search_refresh: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_ripio_payment_method_screen_upsell: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_deeplinks_v2_delegation: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_pano_svl_receive: {
    ios: true,
    android: true,
    web: false,
    extension: false,
  },
  kill_scw_rn_import: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_scw_rn_payments: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_scw_cross_device_import: {
    ios: false,
    android: false,
    web: false,
    extension: true,
  },
  kill_usd_van_accounts: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_usd_van_accounts_us: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_usd_van_accounts_global: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_usd_van_accounts_microdeposits: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_tx_confirmation_rearchitecture_send: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_tx_confirmation_rearchitecture_swap: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  enable_walletlink_modal_v2: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_pano_swap_disconnected_state: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_aleph_crecimiento_popup_event: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_tx_confirmation_rearchitecture_bridge: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_cashout_erc20_to_ach: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_cashout_assets_v2: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_swap_fee: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_wallets_rn_filter_network: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_scw_share_user_id_with_pano: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  enable_cashout_providers_v2: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_mint_feed: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_social_trending_swap: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_composer_actions: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_social_trending_mint: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_ocs_coming_soon: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_introducing_smart_wallet_mint: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_notification_settings_personalization: {
    rn: false,
    extension: false,
    web: true,
  },
  kill_cross_chain_swap_bridge_redirect: {
    ios: true,
    android: true,
    web: false,
    extension: false,
  },
  enable_deeplinks_v2_branch: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_wallet_hide_unhide_assets: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_cross_chain_swap_slippage: {
    ios: true,
    android: true,
    web: true,
    extension: true,
  },
  kill_signout_flow_v2: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_tx_confirmation_rearchitecture_web3: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_linkdrop_v3_12: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_defi_tab_all_networks_rn: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_trading_activity_adp: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_deposit_ui: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_scw_txl_full_screen_disclaimer: {
    ios: false,
    android: false,
    web: false,
    extension: false,
  },
  kill_real_time_swap_sends: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
  kill_real_time_swap_sends_pay_with_usdc: {
    ios: false,
    android: false,
    web: true,
    extension: true,
  },
});
