import { AnyJSON, getJSON, postJSON } from 'cb-wallet-http/fetchJSON';

import { SOLANA_CHAIN_ID } from './constants';
import {
  Collectible,
  CollectibleBidByIdResponse,
  CollectibleMarketplaceListingDetails,
  CollectibleOpenBidsResponse,
  CollectibleSendTxResponse,
  CollectibleTopBidsResponse,
  CollectibleTransactionCombined,
  CollectibleTransactionDataResponse,
  Collection,
  CollectionListingResponse,
  GetTokenListResponse,
  PaginatedCollectionResponse,
  PaginatedCollectionTokenResponse,
  PortfolioCollectible,
  PortfolioCollectiblesResponse,
  PortfolioCollectionsResponse,
  RecentCollectible,
  SupportedCollectibleChainsResponse,
  TopBidsSortBy,
  TopBidsSortOrder,
  UnformattedCollection,
  VerifyNFTTicketResponse,
} from './types';

const PROFILE_NFT_URL =
  'https://www.coinbase.com/api/v3/coinbase.public_api.unauthed.social_share_service_unauthed.SocialShareUnauthedService/GetUserProfileTokens';
export const FETCH_QUERY_KEY = 'collectibles/getUserCollectionList';
export const FETCH_OFFERS_KEY = 'collectibles/token/getOpenBids';
export const FETCH_BIDS_KEY = 'collectibles/getTokenBids';
export const FETCH_BID_BY_ID_KEY = 'collectibles/getBidById';
export const FETCH_TOP_BIDS_KEY = 'collectibles/getTopBids';
export const FETCH_TOKEN_DETAILS_KEY = 'collectibles/getTokenDetails';
export const FETCH_TK_HISTORY_KEY = 'collectibles/getTokenHistory';
export const ETHEREUM_COLLECTION_CHAIN_ID = 1;
export const OPTIMISM_COLLECTION_CHAIN_ID = 10;
export const GNOSIS_COLLECTION_CHAIN_ID = 100;
export const POLYGON_COLLECTION_CHAIN_ID = 137;
export const BASE_COLLECTION_CHAIN_ID = 8453;
export const DEFAULT_COLLECTION_CHAIN_IDS = [ETHEREUM_COLLECTION_CHAIN_ID.toString()];
export const COLLECTION_TRADING_DETAILS_CHAIN_IDS = [
  ETHEREUM_COLLECTION_CHAIN_ID.toString(),
  OPTIMISM_COLLECTION_CHAIN_ID.toString(),
  POLYGON_COLLECTION_CHAIN_ID.toString(),
  BASE_COLLECTION_CHAIN_ID.toString(),
];
export const COLLECTION_VIEW_CHAIN_IDS = [
  ETHEREUM_COLLECTION_CHAIN_ID.toString(),
  BASE_COLLECTION_CHAIN_ID.toString(),
  OPTIMISM_COLLECTION_CHAIN_ID.toString(),
  GNOSIS_COLLECTION_CHAIN_ID.toString(),
  SOLANA_CHAIN_ID.toString(),
  POLYGON_COLLECTION_CHAIN_ID.toString(),
];
export const FETCH_REFRESH_KEY = 'collectibles/refresh';
export const FETCH_ACCEPT_BID_DATA = 'collectibles/getExecuteSell';
export const FETCH_COLLECTIBLE_BUY_DATA = 'collectibles/getExecuteBuy';
export const FETCH_COLLECTION_TOKENS = 'collectibles/getCollectionTokens';
export const FETCH_COLLECTION_LISTINGS = 'collectibles/getListings';
export const FETCH_WALLET_COLLECTION_TOKENS = 'collectibles/getWalletCollectionTokens';
export const FETCH_COLLECTION_OPENSEA_LINK = 'collectibles/getOpenseaCollectionLink';
export const FETCH_RECENT_COLLECTIBLES_KEY = 'collectibles/getRecentAcquiredTokens';
export const FETCH_PENDING_COLLECTIBLES_KEY = 'collectibles/portfolio/pendingTokens';
export const FETCH_PAGINATED_COLLECTIONS_KEY = 'collectibles/portfolio/collections';
export const FETCH_PAGINATED_COLLECTION_TOKENS_KEY = 'collectibles/portfolio/collectionTokens';
export const FETCH_PORTFOLIO_TOKENS_KEY = 'collectibles/portfolio/tokens';
export const FETCH_PORTFOLIO_COLLECTIONS_INFO_KEY = 'collectibles/portfolio/collectionsInfo';
export const FETCH_BUY_LISTING_DATA = 'collectibles/getMarketplaceListingDetails';
export const FETCH_ENABLED_COLLECTIBLE_CHAIN_IDS = 'collectibles/getSupportedChains';
export const VERIFY_NFT_TICKET =
  'https://api.wallet.coinbase.com/rpc/v3/collectibles/claims/verifyOwnership';
export const INCREASED_NFT_PAGE_SIZE = 50;
export const DEFAULT_NFT_PAGE_SIZE = 20;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateID(id: any, contractAddress: string) {
  return [id, contractAddress].join('/');
}

export function formatCollectionResponse(collections: UnformattedCollection[]): Collection[] {
  return collections.map(({ tokens, ...collection }) => ({
    ...collection,
    id: generateID(collection.collectionInfo.chainId, collection.collectionInfo.contractAddress),
    tokens: {
      tokenList:
        tokens.tokenList?.map((collectible) => ({
          ...collectible,
          collectionName: collectible?.collectionName ?? '', // collection name is not always returned from the backend
          id: generateID(collectible.tokenId, collectible.contractAddress),
          spam: collectible?.spam ?? false, // spam flag is not always returned from the backend
        })) || [],
    },
  }));
}

async function getTokenList(address: string): Promise<GetTokenListResponse> {
  const params = {
    address,
  };

  return postJSON(PROFILE_NFT_URL, params, {
    isThirdParty: true,
    authenticated: false,
  });
}

export async function fetchProfileTokens(userAddress?: string) {
  if (!userAddress) return [];
  return getTokenList(userAddress);
}

export async function fetchCollections(
  userAddress?: string,
  chainId = ETHEREUM_COLLECTION_CHAIN_ID.toString(),
  spamFilter = '',
): Promise<Collection[]> {
  if (!userAddress) return [];

  const { result } = await getJSON<AnyJSON>(FETCH_QUERY_KEY, {
    userAddress,
    chainId,
    spamFilter: spamFilter.toString(),
  });

  if (!result?.collections) return [];

  return formatCollectionResponse(result.collections);
}

export async function fetchRecentCollectibles(
  userAddress?: string,
  isRecentCollectiblesEnabled?: boolean,
): Promise<RecentCollectible[]> {
  if (!userAddress || !isRecentCollectiblesEnabled) return [];

  const { result } = await getJSON<AnyJSON>(FETCH_RECENT_COLLECTIBLES_KEY, {
    userAddress,
    limit: '10',
  });

  if (!result?.acquiredTokens) return [];
  return result.acquiredTokens;
}

export async function fetchPendingCollectibles(
  chains?: string,
  evmAddress?: string,
): Promise<PortfolioCollectible[]> {
  if (!evmAddress || !chains) return [];

  const { result } = await getJSON<AnyJSON>(FETCH_PENDING_COLLECTIBLES_KEY, {
    chains,
    evmAddress,
  });

  if (!result) return [];
  return result;
}

export async function refreshCollectibles(
  tokenId: string,
  contractAddress: string,
  chainId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const parameters = {
    contractAddress,
    tokenId,
    chainId,
  };
  const { result } = await postJSON<AnyJSON>(FETCH_REFRESH_KEY, parameters);
  return result;
}

export async function getCollectibleSendTxData(
  contractAddress: string,
  tokenId: string,
  toAddress: string,
  fromAddress: string,
  tokenType: string,
  chainId: string,
  tokenCount = '1',
): Promise<CollectibleSendTxResponse> {
  const { result } = await getJSON<AnyJSON>('collectibles/getSendTxData', {
    chainId,
    contractAddress,
    tokenId,
    tokenCount,
    toAddress,
    fromAddress,
    tokenType,
  });
  return result;
}

export async function getTokenHistory(
  contractAddress?: string,
  tokenId?: string,
  chainId?: string,
): Promise<CollectibleTransactionCombined[]> {
  if (!contractAddress || !tokenId || !chainId) {
    return [];
  }

  const { result } = await getJSON<AnyJSON>(FETCH_TK_HISTORY_KEY, {
    contractAddress,
    tokenId,
    chainId,
  });
  return result;
}

export async function getCollectibleOpenBids(
  contractAddress?: string,
  tokenId?: string,
  chainId?: string,
): Promise<CollectibleOpenBidsResponse> {
  if (!contractAddress || !tokenId || !chainId) {
    return { openBids: [] };
  }

  const { result } = await getJSON<AnyJSON>(FETCH_OFFERS_KEY, {
    contractAddress,
    tokenId,
    chainId,
  });

  if (!result) return { openBids: [] };

  return result;
}

export async function getCollectibleTokenBids(
  contractAddress?: string,
  tokenId?: string,
  chainId?: string,
  continuationToken?: string,
): Promise<CollectibleOpenBidsResponse> {
  if (!contractAddress || !tokenId || !chainId) {
    return { continuationToken: '', openBids: [] };
  }

  const params = {
    contractAddress,
    tokenId,
    chainId,
    sortBy: 'bidValue',
  };

  const { result } = await getJSON<AnyJSON>(
    FETCH_BIDS_KEY,
    continuationToken
      ? {
          ...params,
          continuationToken,
        }
      : params,
  );

  if (!result) return { continuationToken: '', openBids: [] };

  return result;
}

export async function getCollectibleBidById(
  bidId?: string,
): Promise<CollectibleBidByIdResponse | null> {
  if (!bidId) {
    return null;
  }

  const { result } = await getJSON<AnyJSON>(FETCH_BID_BY_ID_KEY, { id: bidId });

  if (!result) return null;

  return result;
}

export async function getCollectibleTopBids(
  userAddress?: string,
  chainId?: string,
  sortBy: TopBidsSortBy = 'bidValue',
  sortOrder: TopBidsSortOrder = 'descending',
  continuationToken?: string,
): Promise<CollectibleTopBidsResponse> {
  if (!userAddress || !chainId) {
    return { continuationToken: '', topBids: [], totalCount: 0 };
  }

  const params = {
    userAddress,
    chainId,
    sortBy,
    sortOrder,
  };

  const { result } = await getJSON<AnyJSON>(
    FETCH_TOP_BIDS_KEY,
    continuationToken
      ? {
          ...params,
          continuationToken,
        }
      : params,
  );

  if (!result) return { continuationToken: '', topBids: [], totalCount: 0 };

  return result;
}

type TokenDetailsParams = {
  contractAddress?: string;
  tokenId?: string;
  includeFloorPrice: string;
  chainId?: string;
  userAddress?: string;
};

export async function getTokenDetails(
  contractAddress?: string,
  tokenId?: string,
  includeFloorPrice = false,
  chainId?: string,
  userAddress?: string,
): Promise<Collectible | null> {
  if (!contractAddress || !tokenId || !chainId) {
    return null;
  }

  const params: TokenDetailsParams = {
    contractAddress,
    tokenId,
    chainId,
    includeFloorPrice: includeFloorPrice ? 'true' : 'false',
    ...(userAddress ? { userAddress } : {}),
  };

  const result = await getJSON<Collectible>(FETCH_TOKEN_DETAILS_KEY, params, { apiVersion: 3 });

  return result ?? null;
}

export async function getAcceptBidData(
  offerId?: string,
  takerAddress?: string,
  collectionAddress?: string,
  tokenId?: string,
): Promise<CollectibleTransactionDataResponse | null> {
  if (!offerId || !takerAddress || !collectionAddress || !tokenId) {
    return null;
  }

  const { result } = await getJSON<AnyJSON>(FETCH_ACCEPT_BID_DATA, {
    orderid: offerId,
    token: `${collectionAddress}:${tokenId}`,
    taker: takerAddress,
  });
  return result ?? null;
}

export async function getCollectionTokens(
  chainId: string,
  contractAddress: string,
  page: string,
  pageSize: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any | null> {
  const { result } = await getJSON<AnyJSON>(FETCH_COLLECTION_TOKENS, {
    ...(page ? { page } : {}),
    contractAddress,
    pageSize,
    chainId,
  });
  return result ?? null;
}

export async function getCollectionListings(
  chainId: string,
  contractAddress: string,
  page: string,
  limit: string,
  marketplaces = 'CBNFT',
) {
  const { result } = await getJSON<{
    result: { listings: CollectionListingResponse[]; nextPage: string };
  }>(FETCH_COLLECTION_LISTINGS, {
    page,
    contractAddress,
    limit,
    chainId,
    marketplaces,
  });
  return result ?? null;
}

export async function getWalletCollectionTokens(
  chainId: string,
  contractAddress: string,
  page: string,
  limit: string,
  walletAddress?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any | null> {
  if (!walletAddress) {
    return null;
  }

  const { result } = await getJSON<AnyJSON>(FETCH_WALLET_COLLECTION_TOKENS, {
    page,
    contractAddress,
    walletAddress,
    limit,
    chainId,
  });
  return result ?? null;
}

export async function getCollectionOpenseaLink(
  chainId: string,
  contractAddress: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any | null> {
  const { result } = await getJSON<AnyJSON>(FETCH_COLLECTION_OPENSEA_LINK, {
    contractAddress,
    chainId,
  });
  return result ?? null;
}

type PaginatedCollectionsParams = {
  cursor?: string;
  chains?: string;
  evmAddress?: string;
  solAddress?: string;
};

export async function fetchPaginatedCollections({
  cursor,
  chains = '1',
  evmAddress,
  solAddress = '',
}: PaginatedCollectionsParams): Promise<PaginatedCollectionResponse | null> {
  if (!evmAddress || !chains) return null;

  const params = {
    chains,
    evmAddress,
    solAddress,
    limit: '10',
    format: 'legacy',
  };

  const { result } = await getJSON<AnyJSON>(
    FETCH_PAGINATED_COLLECTIONS_KEY,
    cursor
      ? {
          ...params,
          cursor,
        }
      : params,
  );

  return result;
}

type PaginatedCollectionTokensParams = {
  limit?: string;
  cursor?: string;
  walletAddress?: string;
  chainId?: string;
  contractAddress?: string;
};

export async function fetchPaginatedCollectionTokens({
  cursor = '',
  chainId = '1',
  walletAddress,
  contractAddress,
}: PaginatedCollectionTokensParams): Promise<PaginatedCollectionTokenResponse | null> {
  if (!chainId || !walletAddress || !contractAddress) return null;

  const params = {
    chainId,
    collectionId: contractAddress,
    walletAddress,
    limit: '10',
  };

  const { result } = await getJSON<AnyJSON>(
    FETCH_PAGINATED_COLLECTION_TOKENS_KEY,
    cursor
      ? {
          ...params,
          cursor,
        }
      : params,
  );

  return result;
}

type BasePortfolioParams = {
  cursor?: string;
  chainIds?: string;
  sortMethod?: string;
  sortDirection?: string;
  spamFilter?: string;
  limit?: number;
  aggregateOE721s?: boolean;
};

type PortfolioCollectiblesParams = BasePortfolioParams & {
  evmAddresses?: string[];
  solAddresses?: string[];
};

export async function fetchPortfolioCollectibles({
  cursor,
  chainIds = '1',
  evmAddresses = [],
  solAddresses = [],
  sortMethod = 'recently_added',
  sortDirection = 'desc',
  spamFilter = '',
  limit = DEFAULT_NFT_PAGE_SIZE,
  aggregateOE721s = true,
}: PortfolioCollectiblesParams): Promise<PortfolioCollectiblesResponse | null> {
  if (!evmAddresses?.filter(Boolean).length) {
    return null;
  }

  const params = {
    chains: chainIds,
    evmAddress: evmAddresses.join(','),
    solAddress: solAddresses?.join(','),
    limit: String(limit),
    sortMethod,
    sortDirection,
    spamFilter: spamFilter.toString(),
    aggregateOE721s: aggregateOE721s.toString(),
  };

  const { result } = await getJSON<AnyJSON>(
    FETCH_PORTFOLIO_TOKENS_KEY,
    cursor
      ? {
          ...params,
          cursor,
        }
      : params,
  );

  return result;
}

type PortfolioCollectionsParams = BasePortfolioParams & {
  evmAddresses?: string[];
  solAddresses?: string[];
};

export async function fetchPortfolioCollections({
  cursor,
  // chains needs to be a string rather than a list as react query requires the parameters
  // to be strings
  chainIds = '1',
  evmAddresses = [],
  solAddresses = [],
  sortMethod = 'recently_added',
  sortDirection = 'desc',
  spamFilter = '',
  limit = DEFAULT_NFT_PAGE_SIZE,
}: PortfolioCollectionsParams): Promise<PortfolioCollectionsResponse | null> {
  if (!evmAddresses?.filter(Boolean).length) {
    return null;
  }

  const params = {
    chains: chainIds,
    evmAddress: evmAddresses.join(','),
    solAddress: solAddresses.join(','),
    limit: String(limit),
    sortMethod,
    sortDirection,
    spamFilter: spamFilter.toString(),
  };

  const { result } = await getJSON<AnyJSON>(
    FETCH_PORTFOLIO_COLLECTIONS_INFO_KEY,
    cursor
      ? {
          ...params,
          cursor,
        }
      : params,
  );

  return result;
}

type CollectibleBuyProps = {
  contractAddress?: string;
  tokenId?: string;
  chainId?: string;
  walletAddress?: string;
  orderId?: string;
  marketplace?: string;
};

export async function getCollectibleMarketplaceListingDetails({
  contractAddress,
  tokenId,
  chainId,
  walletAddress,
  orderId = '',
}: CollectibleBuyProps): Promise<CollectibleMarketplaceListingDetails | null> {
  if (!contractAddress || !tokenId || !chainId || !walletAddress) {
    return null;
  }

  const { result } = await getJSON<AnyJSON>(FETCH_BUY_LISTING_DATA, {
    contractAddress,
    tokenId,
    chainId,
    wallet: walletAddress,
    orderId,
  });

  return result;
}

export async function getExecuteCollectibleBuyData({
  contractAddress,
  tokenId,
  chainId,
  walletAddress,
  orderId = '',
}: CollectibleBuyProps): Promise<CollectibleTransactionDataResponse | null> {
  if (!contractAddress || !tokenId || !chainId || !walletAddress) {
    return null;
  }

  const { result } = await getJSON<AnyJSON>(FETCH_COLLECTIBLE_BUY_DATA, {
    contractAddress,
    tokenId,
    chainId,
    wallet: walletAddress,
    orderId,
  });

  return result;
}

export async function getSupportedCollectibleChains(): Promise<SupportedCollectibleChainsResponse> {
  const { result } = await getJSON<AnyJSON>(FETCH_ENABLED_COLLECTIBLE_CHAIN_IDS, {});

  return result;
}

type NFTTicketingProps = {
  claimer: string;
  chainId: string;
  collectionAddress: string;
  tokenId: string;
  signature: string;
};

export async function verifyNFTTicket({
  claimer,
  chainId,
  collectionAddress,
  tokenId,
  signature,
}: NFTTicketingProps): Promise<VerifyNFTTicketResponse | null> {
  const parameters = {
    claimer,
    token: { chainId, collectionAddress, tokenId },
    signature,
  };
  const { result } = await postJSON<AnyJSON>(VERIFY_NFT_TICKET, parameters, { isThirdParty: true });

  return result;
}
