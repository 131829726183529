import { CurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { FiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/models/FiatCurrency';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { SanitizedBigDecimal } from 'wallet-engine-signing/util/SanitizedBigDecimal';

type FormatAmount = {
  currencyFormatter: CurrencyFormatter;
  wallet: Wallet;
  newAmount: bigint;
  isCrypto: boolean;
  includeCode?: boolean;
  preserveEndingZeros?: boolean;
  overridePrivacy?: boolean;
  isPaymentsMode?: boolean;
};

export function formatAmount({
  currencyFormatter,
  wallet,
  newAmount,
  isCrypto,
  includeCode = false,
  preserveEndingZeros = false,
  overridePrivacy = false,
  isPaymentsMode,
}: FormatAmount) {
  if (isCrypto) {
    return (
      currencyFormatter.formatToCrypto({
        currencyCode: isPaymentsMode ? CurrencyCode.USD : wallet.currencyCode,
        decimals: wallet.decimals,
        value: newAmount,
        includeCode,
        maxDisplayDecimals: 8n,
        minDisplayDecimals: 0n,
        network: wallet.network,
        blockchain: wallet.blockchain,
        overridePrivacy,
      }) || '0.0000'
    );
  }

  return (
    currencyFormatter.formatToFiat({
      fromCurrencyCode: wallet.currencyCode,
      decimals: wallet.decimals,
      value: newAmount,
      isAbbreviated: false,
      contractAddress: wallet.contractAddress,
      network: wallet.network,
      blockchain: wallet.blockchain,
      includeCode,
      preserveEndingZeros,
      formatZeroBalance: true,
    }) || '0.00'
  );
}

export function amountToText(amount: string, isTokenDecimals?: boolean) {
  // hard coded to 1*10^18 because it is always in eth for V1
  const amountNum = isTokenDecimals ? BigInt(amount) / BigInt(1e18) : BigInt(amount);

  const MAX_LENGTH = 5;
  if (amount.length < MAX_LENGTH) {
    return amount;
  }
  const lookup = [
    { value: 1n, symbol: '' },
    { value: BigInt(1e3), symbol: 'K' },
    { value: BigInt(1e6), symbol: 'M' },
    { value: BigInt(1e9), symbol: 'B' },
    { value: BigInt(1e12), symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((num) => {
      return amountNum >= num.value;
    });
  return item
    ? (amountNum / item.value).toString().substr(0, MAX_LENGTH).replace(rx, '$1') + item.symbol
    : '0';
}

export function getFiatSymbol(fiatCurrency: FiatCurrency) {
  return Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: fiatCurrency.code.code,
  })
    .formatToParts()
    .filter((x) => x.type === 'currency')[0].value;
}

export function getSymbol(
  wallet: Wallet,
  isCrypto: boolean,
  fiatCurrency: FiatCurrency = FiatCurrency.USD,
) {
  return isCrypto ? wallet.currencyCode.code : getFiatSymbol(fiatCurrency);
}

// Returns a . or , separator based on the users locale
export function getDecimalSeparator(locale: string) {
  const formattedNum = (1.23).toLocaleString(locale);
  const matches = formattedNum.match(/[^0-9.]/) ?? [];
  return matches[matches.length - 1] || '.';
}

// Returns a . or , separator based on the users locale
export function getThousandsSeparator(locale: string) {
  const formattedNum = (1234).toLocaleString(locale);
  const matches = formattedNum.match(/[^0-9]/) ?? [];
  return matches[0] || ',';
}

// Removes the decimal point character (e.g. '.' or ',').
export function removeDecimalSeparator(amount: string) {
  const removeDecimal = amount.split('.').join('');
  const formattedNum = removeDecimal.split(',').join('');
  return formattedNum;
}

export function normalizeEnteredAmount({
  value,
  decimals,
  isFiat,
  locale = 'en',
}: {
  value: string;
  decimals: bigint;
  isFiat: boolean;
  locale: string;
}) {
  const separator = getDecimalSeparator(locale);
  if (value.split(separator).length > 2) {
    return value.slice(0, value.length - 1);
  }

  let stripped = value
    // Remove everything except digits and separators
    .replace(new RegExp(`[^\\d${separator}]`, 'g'), '');

  const split = stripped.split(separator);
  const keepDecimals = isFiat ? 2 : Number(decimals);

  if (split.length > 1 && split[1].length > keepDecimals) {
    // If there is a decimal portion to the amount, only keep up to decimals length
    stripped = `${split[0]}${separator}${split[1].slice(0, keepDecimals)}`;
  }

  let nextAmountValue: string;

  if (stripped === separator || stripped === '') {
    nextAmountValue = `${Number(stripped)}`;
  } else {
    nextAmountValue = `${SanitizedBigDecimal.create(stripped)}`;
  }

  if (stripped.includes(separator)) {
    nextAmountValue = stripped === separator ? `0${stripped}` : stripped;
  }

  return nextAmountValue;
}
// This regex prevents errors regarding the separator. Example: USD 1..2 and USD 1.2.3
export function checkAmountSeparator({ value, locale = 'en' }: { value: string; locale?: string }) {
  const separator = getDecimalSeparator(locale);
  const regex = new RegExp(`^\\d*\\${separator}?\\d*$`);

  return regex.test(value);
}
