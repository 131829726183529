import { CSSProperties, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { logEvent } from 'cb-wallet-analytics/utils/log';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useSetAppearance } from 'cb-wallet-data/stores/User/hooks/useAppearance';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary';
import { GlobalErrorPage } from 'wallet-cds-web/components/GlobalErrorPage';
import { paletteAliasToRgbaString } from '@cbhq/cds-common/palette/paletteAliasToRgbaString';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { ActionType, ComponentType } from '@cbhq/client-analytics';
import { FetchableResourceProvider } from '@cbhq/instant-api-hooks-core/fetch';
import { DisconnectedState } from ':dapp/components/DisconnectedState/DisconnectedState';
import { BottomNavbar } from './components/BottomNavbar/BottomNavbar';
import { CookiesBanner } from './components/CookiesBanner/CookiesBanner';
import { Navbar } from './components/Navbar/Navbar';
import { Search } from './components/Search/Search';
import { SideNavbar } from './components/SideNavbar/SideNavbar';
import { BOTTOM_NAVBAR_HEIGHT, SIDEBAR_WIDTH_DESKTOP, SIDEBAR_WIDTH_PHONE_LANDSCAPE } from './config/layout';
import { useIsCoinbaseDappBrowser } from './hooks/useIsCoinbaseDappBrowser';
import { getLocalStorageSize } from './utils/getLocalStorageSize';
import { ALLOWED_DISCONNECTED_STATE_ROUTES, ERROR_ROUTES, RoutesEnum } from './utils/RoutesEnum';
type AppLayoutProps = {
  children: ReactNode;
};
const overflowOverrides: Partial<CSSProperties> = {
  overflowY: 'clip',
  overflowX: 'clip',
  zIndex: 0,
  margin: '0 auto'
};
const layoutCentered = "lqsh7tg";
const noSidebarSize = "nry8k8o";
const contentSize = "cy219cy";
export function AppLayout({
  children
}: AppLayoutProps) {
  const router = useRouter();
  const pathname = router.pathname;
  const isOcsPage = !!pathname.startsWith('/ocs');
  const isHomePage = !!pathname.startsWith('/home');
  const queryClient = useQueryClient();
  const {
    isPhone,
    isPhoneLandscape
  } = useBreakpoints();
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();
  const setSpectrum = useSetAppearance();
  const isCoinbaseDappBrowser = useIsCoinbaseDappBrowser();
  const connectedStateCookieValue = getCookie('has_accounts_and_wallet_groups');
  const isDisconnectedState = !hasAccountsAndWalletGroups;
  const errorReportingMetadata = useMemo(() => ({
    localStorageSize: getLocalStorageSize()
  }), []);
  const hasSidebar = useMemo(() => !isDisconnectedState && !isCoinbaseDappBrowser, [isDisconnectedState, isCoinbaseDappBrowser]);
  const handleResetDarkSpectrum = useCallback(() => {
    if (isDisconnectedState) {
      setCookie('spectrum', 'dark');
      setSpectrum('dark');
      const background = paletteAliasToRgbaString('background', 'dark');
      document.documentElement.setAttribute('style', `background-color: ${background}`);
    }
  }, [isDisconnectedState, setSpectrum]);
  useEffect(() => {
    handleResetDarkSpectrum();
  }, [handleResetDarkSpectrum]);
  const syncConnectedStateCookie = useCallback(() => {
    if (connectedStateCookieValue !== hasAccountsAndWalletGroups.toString()) {
      logEvent('has_accounts_and_wallet_groups.mismatch', {
        action: ActionType.render,
        componentType: ComponentType.unknown
      });
    }
    setCookie('has_accounts_and_wallet_groups', hasAccountsAndWalletGroups, {
      maxAge: 60 * 60 * 24 * 7
    });
  }, [connectedStateCookieValue, hasAccountsAndWalletGroups]);
  useEffect(() => {
    syncConnectedStateCookie();
  }, [syncConnectedStateCookie]);
  const layoutMaxWidth = useMemo(() => {
    if (isPhone || router.pathname.includes(RoutesEnum.SMART_WALLET)) {
      return '100vw';
    }
    if (isPhoneLandscape) {
      return hasSidebar ? `calc(100vw - ${SIDEBAR_WIDTH_PHONE_LANDSCAPE}px)` : `100vw`;
    }
    return hasSidebar ? `calc(100vw - ${SIDEBAR_WIDTH_DESKTOP}px)` : `100vw`;
  }, [isPhone, isPhoneLandscape, router.pathname, hasSidebar]);
  const containerStyle = useMemo(() => ({
    marginBottom: isPhone ? BOTTOM_NAVBAR_HEIGHT : 0
  }), [isPhone]);
  const handleDismiss = useCallback(() => {
    router.replace(RoutesEnum.ASSETS).then(() => {
      queryClient.clear();
      router.reload();
    });
  }, [router, queryClient]);
  const featureNav = useMemo(() => {
    if (isCoinbaseDappBrowser) return null;
    if (isPhone) {
      return <BottomNavbar />;
    }
    return <SideNavbar />;
  }, [isPhone, isCoinbaseDappBrowser]);
  if (isDisconnectedState && !ALLOWED_DISCONNECTED_STATE_ROUTES.includes(router.pathname) && !ERROR_ROUTES.includes(router.pathname)) {
    return <ErrorBoundary fallback={<GlobalErrorPage onDismiss={handleDismiss} />} context="dapp_error">
        <FetchableResourceProvider>
          <Box height="100vh">
            <Search />
            <VStack width="100%">
              <Navbar />
              <DisconnectedState />
            </VStack>
          </Box>
        </FetchableResourceProvider>
      </ErrorBoundary>;
  }
  return <ErrorBoundary fallback={<GlobalErrorPage onDismiss={handleDismiss} />} context="dapp_error">
      <FetchableResourceProvider>
        <Box style={containerStyle}>
          <Search />
          {/* Show side/bottom navbar only in connected state */}
          {!isDisconnectedState && featureNav}
          <VStack className={hasSidebar ? contentSize : noSidebarSize}>
            <Navbar />
            {/* This allows users to access the SideNavbar and navbar if any page throws an error */}
            <ErrorBoundary fallback={<GlobalErrorPage onDismiss={handleDismiss} />} context="dapp_error" metadata={errorReportingMetadata}>
              <Box testID="dapp-app-layout-children-wrapper" height="100%" width="100%" position="relative" style={overflowOverrides} className={isOcsPage || isHomePage ? layoutCentered : undefined} display="block" maxWidth={isOcsPage || isHomePage ? undefined : layoutMaxWidth} flexGrow={1}>
                {children}
              </Box>
            </ErrorBoundary>
          </VStack>
          <CookiesBanner />
        </Box>
      </FetchableResourceProvider>
    </ErrorBoundary>;
}

require("./AppLayout.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./AppLayout.tsx");