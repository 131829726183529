/* eslint-disable camelcase */

export type KillSwitchCrossPlatform = keyof typeof killSwitchesCrossPlatformConfig;

/**
 * For new kill switches, please use killswitchesByPlatformConfig.
 *
 * These are legacy kill switches where the same one is used across platforms.
 */
export const killSwitchesCrossPlatformConfig = {
  kill_sponsored_gas_send_rn2: true,
  kill_sponsored_gas_send_extension2: true,
  kill_experiments_local_override: true,
  kill_miami_illustrations: false,
  kill_switch_test: false,
  kill_profile_list_cell_follow_button: false,
  kill_profile_follow_counts: false,
  kill_profile_pfp_list_cell: false,
  kill_terms_of_service_update: false,
  kill_switch_eth_pow_fork_warning: false,
  kill_quick_buy_eth_card: false,
  kill_cbpay_buy_default_experiment: false,
  kill_browser_persistent_nux_cards: false,
  kill_nux_nft_incentive_retry_air_drop: false,
  kill_bbml2_app_to_app_oauth2_v2: false,
  kill_dapp_browser_top_dapps_solana: false,
  kill_payment_messsaging_flow: false,
  kill_messaging_payment_messages: false,
  kill_sign_xmtp_notification: false,
  kill_ledger_onboarding: false,
  kill_switch_aggregate_bids_ext: false,
  kill_solana_nft_refresh_ext: false,
  kill_walletlink_link: false,

  // sponsored sends
  enable_sponsored_sends_1: false,
  enable_sponsored_sends_10: false,
  enable_sponsored_sends_137: false,
  enable_sponsored_sends_8453: false,
  enable_sponsored_sends_42161: false,
  enable_sponsored_sends_43114: false,
  enable_sponsored_sends_eurc_8453: false,
  enable_sponsored_sends_cbeth_8453: false,
  enable_sponsored_sends_cbbtc_8453: false,
  enable_sponsored_sends_cbbtc_84532: false,
} as const;

export { killSwitchesCrossPlatformConfig as Killswitches };
