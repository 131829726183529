export enum RoutesEnum {
  ASSETS = '/assets',
  ASSETS_CRYPTO = '/assets/crypto', // [id]
  ASSETS_NFT = '/assets/nft', // [address]/[id]
  ASSETS_NFT_COLLECTION = '/assets/nft/collection', // [address]
  ASSETS_NFT_COLLECTION_OPEN_MINTS = '/assets/nft/collection/open-mints', // [address]
  ASSETS_TRANSACTIONS = '/assets/transactions', // [id]
  ASSETS_TESTNETS = '/assets/testnets',
  ASSETS_DEFI = '/assets/defi',
  ASSETS_CREATIONS = '/assets/creations',
  BUY = '/buy',
  CLAIM = '/claim',
  DAPPS = '/apps',
  DEBUG = '/debug',
  HOME = '/',
  HOME_ROOT = '/home',
  HOME_TRENDING = '/home/trending',
  HOME_EXPLORE = '/home/explore',
  IMPORT = '/import',
  IMPORT_REDIRECT = '/import/redirect',
  LOGIN = '/login',
  LOGIN_LOCAL = '/login-local',
  LOGIN_LOCAL_SUCCESS = '/login-local/success',
  MESSAGES = '/messages',
  QUESTS = '/quests',
  STAKE = '/stake',
  SEND = '/send',
  RECEIVE = '/receive',
  SETTINGS = '/settings',
  MINTS = '/nft/mint',
  SMART_WALLET = '/smart-wallet',
  SMART_WALLET_LOGOUT = '/smart-wallet/logout',
  SWAP = '/swap',
  DO_NOT_USE_DATA_DOG_SYNTHETICS = '/do_not_use_datadog_synthetics',
  CASHOUT = '/cashout',
  MWP = '/wsegue',
}

export enum SettingRoutesEnum {
  SETTINGS = '/settings',
  MANAGE_WALLETS = '/settings/manage-wallets',
  DISPLAY = '/settings/display',
  LOCAL_CURRENCY = '/settings/display/local-currency',
  COINBASE_CONNECT = '/settings/coinbase-connect',
  HIDE_ASSETS = '/settings/hide-assets',
}

export type AppRoutesEnum = RoutesEnum | SettingRoutesEnum;

/**
 * Pathnames used for pagekeys
 *
 * subset of RouterPathname
 */
export const PageKeysPathname = {
  ASSETS: '/assets',
  CRYPTO: '/assets/crypto',
  DEFI: '/assets/defi',
  NFT: '/assets/nft',
  TRANSACTIONS: '/assets/transactions',
  CREATIONS: '/assets/creations',
  TESTNETS: '/assets/testnets',
  SETTINGS: '/settings',
  DAPPS: '/apps',
  QUESTS: '/quests',
  OCS: '/ocs', // required for pageKeyRegex since /ocs/coffeedays is still active
  HOMEROOT: '/home',
  MINT: '/nft/mint',
};

/**
 * Corresponds to the file-system based nextjs pages router
 * accessed by `router.pathname`
 */
export const RouterPathname = {
  CRYPTO_DETAIL: '/assets/crypto/[id]',
  CRYPTO_PREVIEW: '/assets/crypto/[id]/preview',
  MINT_DETAIL: '/nft/mint/[contractAddress]',
  NFT_DETAIL: '/assets/nft/[address]/[id]',
  NFT_PREVIEW: '/assets/nft/[address]/[id]/preview',
  NFT_COLLECTION: '/assets/nft/collection/[address]',
  CREATE_NFT_LANDING: '/assets/nft/create',
  GALLERY_DETAIL: '/nft/gallery/[galleryId]',
  TRANSACTIONS_DETAIL: '/assets/transactions/[txHash]',
  TRANSACTIONS_PREVIEW: '/assets/transactions/[txHash]/preview',
  SWAP: '/swap',
  SETTINGS_MANAGE_WALLETS: '/settings/manage-wallets',
  SETTINGS_DISPLAY: '/settings/display',
  SETTINGS_LOCAL_CURRENCY: '/settings/display/local-currency',
  DO_NOT_USE_DATA_DOG_SYNTHETICS: '/do_not_use_datadog_synthetics',
  QUESTS_HISTORY: '/quests/history',
  QUESTS_DETAIL: '/quests/[questId]',
  QUESTS_START: '/quests/[questId]/start',
  QUESTS_ONBOARDING: '/quests/onboarding',
  QUESTS_FUNDING: '/quests/funding',
  OCS_COFFEE_DAYS: '/ocs/coffeedays',
  ONCHAIN_VISION: '/create/onchainvision',
  GALLERY: '/nft/gallery/[galleryId]',
  HOME: '/',
  HOME_ROOT: '/home',
  HOME_TRENDING: '/home/trending',
  HOME_EXPLORE: '/home/explore',
  MWP: '/wsegue',
  ...PageKeysPathname,
};

// Routes that are allowed to be accessed when the user has not connected any wallets
export const ALLOWED_DISCONNECTED_STATE_ROUTES = [
  RouterPathname.CRYPTO_DETAIL,
  RouterPathname.MINT_DETAIL,
  RouterPathname.NFT_DETAIL,
  RouterPathname.NFT_COLLECTION,
  RoutesEnum.IMPORT,
  RoutesEnum.IMPORT_REDIRECT,
  RoutesEnum.DEBUG,
  RoutesEnum.LOGIN,
  RoutesEnum.LOGIN_LOCAL,
  RoutesEnum.LOGIN_LOCAL_SUCCESS,
  RoutesEnum.DO_NOT_USE_DATA_DOG_SYNTHETICS,
  RouterPathname.QUESTS,
  RouterPathname.QUESTS_HISTORY,
  RouterPathname.QUESTS_DETAIL,
  RouterPathname.OCS_COFFEE_DAYS,
  RoutesEnum.SMART_WALLET,
  RoutesEnum.SMART_WALLET_LOGOUT,
  RouterPathname.GALLERY,
  RoutesEnum.CLAIM,
  RouterPathname.CREATE_NFT_LANDING,
  RouterPathname.HOME_ROOT,
  RouterPathname.HOME_TRENDING,
  RouterPathname.HOME_EXPLORE,
  RouterPathname.ONCHAIN_VISION,
  RouterPathname.MWP,
];

export const ERROR_ROUTES = ['/404'];

export type RouterPathnameKeys = Lowercase<keyof typeof PageKeysPathname>;

// For certain promotional pages we don't want to kick off a full tx refresh
export const SKIP_TX_REFRESH_ROUTES = [RoutesEnum.MINTS];
